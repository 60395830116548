import ContactUsSection from "../../component/organism/Home/ContactUs";
import ContactUsHeroSection from "../../component/organism/ContactUs/HeroSection";
import {useEffect} from "react";

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo({top:0, left:0, behavior: "smooth"})
    }, []);
    return(
        <div>
            <ContactUsHeroSection />
            <ContactUsSection />
        </div>
    );
};

export default ContactUs;