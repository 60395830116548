import { useEffect } from "react";
import AntimalwareQaMobile from "./Mobile";
import AntimalwareQaDesktop from "./Desktop";
import {useSelector} from "react-redux";

const AntimalwareQa = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    return (
        <>
            {isMobile ?
                <AntimalwareQaMobile />
                :
                <AntimalwareQaDesktop />
            }
        </>
    );
};

export default AntimalwareQa;