import MobileAboutUsSection from "./Mobile";
import DesktopAboutUsSection from "./Desktop";
import {useSelector} from "react-redux";

const AboutUsSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    return (
        <>
            {isMobile ?
                <MobileAboutUsSection/>
                :
                <DesktopAboutUsSection/>
            }
        </>
    );
};

export default AboutUsSection;