import styles from "./style.module.scss";

import faqData from "../../../../../assets/lang/en/FAQ/en.json";
import rectangle from "../../../../../assets/backgrounds/Home/FaqSection/Rectangle.svg";
import CustomFaqDropdown from "../../../../molecul/Home/FaqSection/CustomFaqDropdown";
import {useState} from "react";

const DesktopFaqSection = () => {
    const [activeQuestion, setActiveQuestion] = useState(0.1);

    return (
        <section className={styles.main_section}>
            <div className={styles.hashtag_ribbon}>
                <span>#Collaborative<span>team</span></span>
                <span>#Responsibile<span>team</span></span>
                <span>#Reliable<span>team</span></span>
                <span>#Flexible<span>team</span></span>
            </div>

            <div className={styles.questions_section}>
                <div className={styles.top_rectangle_div}>
                    <img src={rectangle} alt={"rectangle"} className={styles.top_rectangle}/>
                </div>
                <CustomFaqDropdown
                    number={1}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={(number) => {
                        setActiveQuestion(number);
                    }}
                    question={faqData.question1.question}
                    answer={faqData.question1.answer}
                />
                <CustomFaqDropdown
                    number={2}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={(number) => {
                        setActiveQuestion(number);
                    }}
                    question={faqData.question2.question}
                    answer={faqData.question2.answer}
                />
                <CustomFaqDropdown
                    number={3}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={(number) => {
                        setActiveQuestion(number);
                    }}
                    question={faqData.question3.question}
                    answer={faqData.question3.answer}
                />
                <CustomFaqDropdown
                    number={4}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={(number) => {
                        setActiveQuestion(number);
                    }}
                    question={faqData.question4.question}
                    answer={faqData.question4.answer}
                />
                <img src={rectangle} alt={"rectangle"} className={styles.bottom_rectangle}/>
            </div>
        </section>
    );
};

export default DesktopFaqSection;