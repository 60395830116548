import styles from "./style.module.scss";
import welcomeBg from "../../../../assets/backgrounds/Careers/HeadingWelcome.png";
import headingBgIcon from "../../../../assets/backgrounds/Careers/headingBgIcon.png";

const CareersHeroSection = () => {
    return (
        <section className={styles.main_section}>
            <div className={styles.section_heading}>
                <div className={styles.first_line}>
                    <img src={welcomeBg} alt={"welcome"}/>
                    <h1>to<span className={"underline_text_mobile_orange"}> Spesna</span></h1>
                </div>
                <h1> Where Your Career<br/>Takes Flight </h1>
            </div>
            <p className={styles.section_paragraph}>
                At Spesna, we believe that our greatest asset is our people. We are a dynamic team of innovators,
                problem-solvers, and visionaries working together to shape the future of digital experiences.
                If you're passionate about technology, creativity, and making a meaningful impact,
                we invite you to explore exciting career opportunities with us.
            </p>
            <img src={headingBgIcon} alt={"welcome"} className={styles.apply_now_icon}/>
        </section>
    );
};

export default CareersHeroSection;