import {useRoutes} from "./useRoutes";
import "./assets/style.scss";
import {useDispatch, useSelector} from "react-redux";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {changeScreenType} from "./redux/features/isMobileSlice";
import {useEffect} from "react";
import CustomNotificationBar from "./component/atom/CustomNotificationBar";

function App() {
    const routes = useRoutes();
    const dispatch = useDispatch();


    const handleResize = (event) => {
        const width = event.target.innerWidth
        if (width > 1040) {
            dispatch(changeScreenType(false));
        } else {
            dispatch(changeScreenType(true));
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    return (
        <ThemeProvider
            theme={createTheme({
                breakpoints: {
                    values: {
                        laptop: 1040,
                        tablet: 720,
                        mobile: 0,
                        desktop: 1420,
                    },
                },
            })}
        >
            {routes}
        </ThemeProvider>
    );
}

export default App;
