import styles from './style.module.scss';

import React, {useCallback, useEffect, useState} from "react";
import closeIcon from "../../../../../assets/icons/Layout/Sidebar/closeIcon.svg";
import leftArrow from "../../../../../assets/icons/Layout/Sidebar/leftArrow.svg";

import {Link, useNavigate} from "react-router-dom";
import navigateIcon from "../../../../../assets/icons/Layout/Navbar/Dropdown/navigateIcon.svg";

const MobileSideBar = ({closeSidebar, isOpen}) => {
    const navigate = useNavigate();
    const [isSecondOpen, setIsSecondOpen] = useState(false);
    const backBtnFunc = useCallback((event) => {
        event.preventDefault();
        closeSidebar();
    }, [closeSidebar])
    useEffect(() => {
        window.scrollTo(0, 0);
        if (isOpen) {
            window.addEventListener('popstate', backBtnFunc);
            document.body.style.overflow = "hidden"; // disable scrolling on the page
        } else {
            document.body.style.overflow = "auto"; // re-enable scrolling on the page
            window.removeEventListener('popstate', backBtnFunc)
        }
    }, [isOpen, backBtnFunc]);

    const handleCloseSidebar = () => {
        setIsSecondOpen(false);
        closeSidebar();
    }
    return (
        <div className={isOpen ? styles.sidebar_open : styles.sidebar}>
            <div className={styles.close_sidebar_btn_div}>
                <button onClick={closeSidebar} className={styles.close_sidebar_btn}>
                    <img width="27" height="27" src={closeIcon} alt={"closeIcon"}/>
                </button>
            </div>
            <div className={styles.sidebar_section}>
                <Link to={'/about_us'} onClick={handleCloseSidebar}>
                    About Us
                </Link>
                <Link to={false} onClick={() => {
                    setIsSecondOpen(true)
                }}>
                    Services
                </Link>
                <Link to={'/case_studies'} onClick={handleCloseSidebar}>
                    Case Studies
                </Link>
                <Link to={'/careers'} onClick={handleCloseSidebar}>
                    Careers
                </Link>
                <button className={styles.contuct_us_btn} onClick={() => {
                    handleCloseSidebar();
                    navigate('/contact_us');
                }}>
                    CONTACT us
                </button>
            </div>
            <div className={isSecondOpen ? styles.sidebar_open : styles.sidebar}>
                <div className={styles.close_sidebar_btn_div}>
                    <button onClick={() => {
                        handleCloseSidebar();
                    }} className={styles.close_sidebar_btn}>
                        <img width="27" height="27" src={closeIcon} alt={"closeIcon"}/>
                    </button>
                </div>
                <div className={styles.left_align}>
                    <button className={styles.back_btn} onClick={() => {
                        setIsSecondOpen(false);
                    }}>
                        <img src={leftArrow} alt={'icon'} />
                        <span> back</span>
                    </button>
                </div>
                <div className={styles.rectangle_div + ' ' + styles.webBg}>
                    <div className={styles.rectangle_inner_div}>
                        <h3>
                            Web Development
                        </h3>
                        <p>
                            Establishes online presence, reaches wider audience,
                            provides essential services.
                        </p>
                        <button className={styles.navigate_btn} onClick={() => {
                            navigate("/services/development");
                            handleCloseSidebar();
                        }}>
                            <img src={navigateIcon} alt={"icon"} className={styles.shaky_img}/>
                        </button>
                    </div>
                </div>
                <div className={styles.rectangle_div + ' ' + styles.qaBg}>
                    <div
                        onClick={() => {
                            navigate("/services/quality_assurance");
                            handleCloseSidebar();
                        }}
                        className={styles.rectangle_inner_div}
                    >
                        <h3>
                            QUALITY ASSURANCE
                        </h3>
                        <p>
                            Ensures reliable products, boosts customer satisfaction and loyalty.
                        </p>
                        <button className={styles.navigate_btn}>
                            <img src={navigateIcon} alt={"icon"} className={styles.shaky_img}/>
                        </button>
                    </div>
                </div>
                <div className={styles.rectangle_div + ' ' + styles.uiUxBg}>
                    <div
                        onClick={() => {
                            navigate("/services/design");
                            handleCloseSidebar();
                        }}
                        className={styles.rectangle_inner_div}
                        style={{
                            margin: '0'
                        }}
                    >
                        <h3>
                            UI/UX DESIGN
                        </h3>
                        <p>
                            Impacts user experience, engagement, conversions,
                            and brand perception for business success.
                        </p>
                        <button className={styles.navigate_btn}>
                            <img src={navigateIcon} alt={"icon"} className={styles.shaky_img}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileSideBar;