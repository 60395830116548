import {useLocation, useParams} from "react-router-dom";
import {
    DesignHeroSection,
    UiUxImportanceSection,
    UiUxProcessSection,
    UiUxServicesSection
} from "../../component/organism/Services/UiUxDesign";
import {
    QaHeroSection,
    QaImportanceSection,
    QaServicesSection,
    QaProcessSection
} from "../../component/organism/Services/QualityAssurance";
import ContactUsSection from "../../component/organism/Home/ContactUs";
import {
    DevHeroSection,
    DevImportanceSection,
    DevProcessSection,
    DevServicesSection
} from "../../component/organism/Services/Development";
import {useEffect} from "react";

const Services = () => {
    const path = useParams().serviceType
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({top:0, left:0, behavior: "smooth"})
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location, path]);

    if (path === "development") {
        return (
            <>
                <DevHeroSection />
                <DevImportanceSection />
                <DevServicesSection />
                <DevProcessSection />
                <ContactUsSection scrollBtnPosition={"-400vh"}/>
            </>
        );
    } else if (path === "quality_assurance") {
        return (
            <>
                <QaHeroSection />
                <QaImportanceSection />
                <QaServicesSection />
                <QaProcessSection />
                <ContactUsSection scrollBtnPosition={"-400vh"}/>
            </>
        );
    } else {
        return (
            <>
                <DesignHeroSection />
                <UiUxImportanceSection />
                <UiUxServicesSection />
                <UiUxProcessSection />
                <ContactUsSection scrollBtnPosition={"-400vh"}/>
            </>
        );
    }
};

export default Services;