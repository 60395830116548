import styles from "./style.module.scss";
import sectionBg from "../../../../../../assets/backgrounds/Home/HeroSection/webDevelopmentBg.png"
const WebDevelopment = () => {
    return(
        <div className={styles.web_development_section}>

            <div className={styles.full_width_flex}>
                <img src={sectionBg} alt={"bg"} className={styles.section_bg}/>
            </div>
        </div>
    );
};

export default WebDevelopment;