import styles from "./style.module.scss";
import {useEffect, useState} from "react";
import DescriptionSection from "./DescriptionSection";
import checkIcon from "../../../../assets/icons/Careers/check.svg";
import ApplySection from "./ApplySection";


const CareersPopUp = ({jobDescription, close}) => {
    const [sectionNumber, setSectionNumber] = useState(1);



    useEffect(() => {

            document.body.style.overflow = "hidden"; // disable scrolling on the page
            document.body.style.height = "100vh";
            document.body.style.top = "0";
            document.body.style.position = "fixed";

      return () => {
            document.body.style.overflow = "auto"; // re-enable scrolling on the page
            document.body.style.height = "auto";
            document.body.style.top = "0";
            document.body.style.position = "unset";
        }
    }, []);

    return(
        <div className={styles.popupContainer} onClick={close}>
            <div className={styles.popupBody} onClick={(e) => {e.stopPropagation()}}>
                    <div className={styles.complete_toggle}>
                        <div className={styles.first_circle} onClick={() => {
                            setSectionNumber(1);
                        }}>
                            <img src={checkIcon} alt={"icon"}/>
                        </div>
                        <hr />
                        {sectionNumber ===1
                            ?
                            <div className={styles.second_circle}>
                                <span/>
                            </div>
                            :
                            <div className={styles.first_circle}>
                                <img src={checkIcon} alt={"icon"}/>
                            </div>
                        }

                        <button className={styles.close_btn} onClick={close}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                <path d="M30.0263 0.492676V5.59961L20.5663 15.0725L30.0263 24.5653V29.6723H24.9263L15.4663 20.1794L5.98625 29.6723H0.90625V24.5653L10.3663 15.0725L0.90625 5.59961V0.492676H6.00625L15.4863 9.96553L24.9263 0.492676H30.0263Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                {
                    sectionNumber === 1
                        ?
                        <DescriptionSection description={jobDescription} changeSection={(number) => {
                            setSectionNumber(number)
                        }}/>
                        :
                        <ApplySection description={jobDescription}/>
                }

            </div>
        </div>
    )
}

export default CareersPopUp;