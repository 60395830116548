import styles from "./style.module.scss";
import UnitImg from "../../../../../../assets/backgrounds/Services/Qa/UnitImg.png";
import SystemImg from "../../../../../../assets/backgrounds/Services/Qa/SystemImg.png";
import InterfaceImg from "../../../../../../assets/backgrounds/Services/Qa/InterfaceImg.png";
import RegressionImg from "../../../../../../assets/backgrounds/Services/Qa/RegressionImg.png";
import AcceptanceImg from "../../../../../../assets/backgrounds/Services/Qa/AcceptanceImg.png";
import IntegrationImg from "../../../../../../assets/backgrounds/Services/Qa/IntegrationImg.png";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import orangeLineImg from "../../../../../../assets/backgrounds/Services/BlueLine.svg";

const MobileServicesSection = () => {
    return (
        <section className={styles.main_section}>
            <div className={styles.section_heading}>
                <h2>
                    services
                </h2>
            </div>
            <Swiper
                slidesPerView={1.6}
                slidesPerGroup={1}
                spaceBetween={0}
                className={styles.hero_swiper}
            >
                <SwiperSlide  className={styles.hero_slide}>
                        <img src={UnitImg} alt={"img"} />
                        <h4>Unit testing</h4>
                </SwiperSlide>
                <SwiperSlide  className={styles.hero_slide}>
                        <img src={SystemImg} alt={"img"} />
                        <h4>System testing</h4>
                </SwiperSlide>
                <SwiperSlide  className={styles.hero_slide}>
                        <img src={InterfaceImg} alt={"img"} />
                        <h4>Interface Testing</h4>
                </SwiperSlide>
                <SwiperSlide  className={styles.hero_slide}>
                        <img src={RegressionImg} alt={"img"}/>
                        <h4>Regression testing</h4>
                </SwiperSlide>
                <SwiperSlide  className={styles.hero_slide}>
                        <img src={AcceptanceImg} alt={"img"} />
                        <h4>user Acceptance<br/>Testing</h4>
                </SwiperSlide>
                <SwiperSlide  className={styles.hero_slide}>
                        <img src={IntegrationImg} alt={"img"} />
                        <h4>Integration testing</h4>
                </SwiperSlide>
            </Swiper>
            <div className={styles.section_btn_div}>
                <button className={styles.section_btn}>
                    GET IN touch
                    <img src={orangeLineImg} alt={"line"}/>
                </button>
            </div>
        </section>
    );
};

export default MobileServicesSection;