import {useSelector} from "react-redux";
import MobileContactUsSection from "./Mobile";
import DesktopContactUsSection from "./Desktop";


const ContactUsSection = ({scrollBtnPosition}) => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileContactUsSection  />
                :
                <DesktopContactUsSection scrollBtnPosition={scrollBtnPosition}/>
            }
        </>
    );
};

export default ContactUsSection;