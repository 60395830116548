import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {useCallback, useEffect, useRef, useState} from "react";
import ContactUsSection from "../../Home/ContactUs";
import {useSelector} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import slide1Img from "../../../../assets/backgrounds/CaseStudes/slide1.webp";
import slide2Img from "../../../../assets/backgrounds/CaseStudes/slide2.webp";
import slide3Img from "../../../../assets/backgrounds/CaseStudes/slide3.webp";
import slide4Img from "../../../../assets/backgrounds/CaseStudes/slide4.webp";
import slide5Img from "../../../../assets/backgrounds/CaseStudes/slide5.webp";
import slide6Img from "../../../../assets/backgrounds/CaseStudes/slide6.webp";
import slide7Img from "../../../../assets/backgrounds/CaseStudes/slide7.webp";
import {Autoplay} from "swiper";
import orangeLineImg from "../../../../assets/backgrounds/CaseStudes/WhiteLine.png";

const MeatHouseDesign = () => {
    const sectionRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const handleScroll = useCallback(() => {
        if (sectionRef.current) {
            const sectionTop = sectionRef.current.getBoundingClientRect().top;

            const sectionHeight = sectionRef.current.getBoundingClientRect().height;

            const currentPosition = isMobile
                ? sectionTop + sectionHeight - window.innerHeight + 170
                :
                sectionTop + sectionHeight - window.innerHeight + 110;
            setScrollPosition(currentPosition);
        }
    }, [isMobile])
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);


    return (
        <div className={styles.main_section}>
            <div className={styles.hero_section}>
                <h1>
                    UI/UX DESIGN SOLUTION FOR ONLINE MEAT STORE
                </h1>
                <div className={styles.scroll_down_icon}/>
            </div>
            <Grid
                container
                spacing={{mobile: 0, tablet: 0, laptop: 0, desktop: 0}}
                alignItems="flex-start"
                rowGap={"50px"}
                justifyContent="space-between"
                className={styles.overview_div}
            >
                <Grid mobile={12} tablet={12} laptop={3} desktop={3} className={styles.categories_div}>
                    <div>
                        <h3>
                            Category
                        </h3>
                        <h6>
                            E-commerce website
                        </h6>
                    </div>
                    <div>
                        <h3>
                            type
                        </h3>
                        <h6>
                            website design
                        </h6>

                    </div>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={4} desktop={4} className={styles.paragraph_div}>
                    <h3>
                        overview
                    </h3>
                    <h6>
                        "The Meat House," a premium meat retailer, is looking to establish a robust online presence by
                        developing an e-commerce platform.
                    </h6>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={4} desktop={4} className={styles.paragraph_div}>
                    <h3>
                        challenge
                    </h3>
                    <h6>
                        This project involves creating a user-friendly and efficient online store that allows customers
                        to browse, select, and purchase a wide variety of high-quality meats and related products.

                    </h6>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={12} desktop={12} className={styles.button_grid}>
                    <button className={styles.section_btn}>
                        VIEW website
                        <img src={orangeLineImg} alt={"line"}/>
                    </button>
                </Grid>
            </Grid>
            <div
                ref={sectionRef}
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: `center ${-scrollPosition}px`,
                }}
                className={styles.background_scroll_img}
            />

            <Swiper
                loop={true}
                modules={[Autoplay]}
                className={styles.hero_swiper}
                autoplay={{
                    delay: 1500,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                        slidesPerGroup: 1,
                    },
                    360: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                        slidesPerGroup: 1,
                    },
                    550: {
                        slidesPerView: 2.1,
                        spaceBetween: 20,
                        slidesPerGroup: 1,
                    },
                    // when window width is >= 320px
                    650: {
                        slidesPerView: 2.6,
                        spaceBetween: 20,
                        slidesPerGroup: 1,
                    },
                    // when window width is >= 480px
                    1300: {
                        slidesPerView: 3.2,
                        spaceBetween: 20,
                        slidesPerGroup: 1,
                    },
                    // when window width is >= 640px
                    1500: {
                        slidesPerView: 4.2,
                        spaceBetween: 20,
                        slidesPerGroup: 1,
                    }
                }}
            >
                <SwiperSlide className={styles.hero_slide}>
                    <img src={slide1Img} alt={"img"}/>
                </SwiperSlide>
                <SwiperSlide className={styles.hero_slide}>
                    <img src={slide2Img} alt={"img"}/>
                </SwiperSlide>
                <SwiperSlide className={styles.hero_slide}>
                    <img src={slide3Img} alt={"img"}/>
                </SwiperSlide>
                <SwiperSlide className={styles.hero_slide}>
                    <img src={slide4Img} alt={"img"}/>
                </SwiperSlide>
                <SwiperSlide className={styles.hero_slide}>
                    <img src={slide5Img} alt={"img"}/>
                </SwiperSlide>
                <SwiperSlide className={styles.hero_slide}>
                    <img src={slide6Img} alt={"img"}/>
                </SwiperSlide>
                <SwiperSlide className={styles.hero_slide}>
                    <img src={slide7Img} alt={"img"}/>
                </SwiperSlide>
            </Swiper>
            <div className={styles.main_solution_div}>
                <h2>
                    Solution
                </h2>
                <p>
                    Our proposed solution for "The Meat House" online store UI/UX design focuses on creating an
                    appealing
                    and user-centered interface that enhances the shopping experience and effectively showcases the
                    brand's
                    premium meat products. Website functional minimalism ensures an efficient shopping experience,
                    focusing
                    on essential functions and straightforward navigation. <br/><br/>
                    We expanded upon the red and black color scheme, upholding the primary utilization of warm tones.
                    These colors evoke a perception of quality that is commonly associated with premium meat products.
                    We chose fonts that strike a balance between modernity and readability. Serif font for headings and
                    branding elements exudes a classic and high-quality feel, which aligns perfectly with the brand's
                    identity. And for body text and product descriptions, we chose clean and legible sans-serif fonts
                    for
                    a smooth and engaging reading experience across all devices.
                </p>
            </div>
            <ContactUsSection />

        </div>
    );
};

export default MeatHouseDesign;