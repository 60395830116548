import styles from "./style.module.scss";
import {useCallback, useEffect, useRef, useState} from "react";
import useIsVisible from "../../../../../../Hooks/useIsVisible";


const getCurrentDimension = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
};
const MobileUiUxProcessSection = () => {
    const [startTimer, setStartTimer] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const [timer, setTimer] = useState(0)

    const sectionRef = useRef(null);

    const isVisible = useIsVisible(sectionRef);

    const incrementTimer = useCallback(() => {
        setTimer((oldTimer) => oldTimer + 1)
    }, [])

    useEffect(() => {
        if(isVisible) setStartTimer(true)
    }, [isVisible])

    useEffect(() => {
        let timeoutFunction
        if (timer === 5) {
            return
        } else if (startTimer) {
            timeoutFunction = setInterval(incrementTimer, 700)
        }
        return () => clearInterval(timeoutFunction);
    }, [incrementTimer, timer, startTimer])
    return (
        <section className={styles.main_section}>
            <div className={styles.ul_div} ref={sectionRef} style={{
                visibility: timer >= 1 ? "visible" : "hidden",
                opacity: timer >= 1 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div}>
                    <div className={styles.number_div}>
                        1
                    </div>
                    <h6>
                        Design Brief<br/>and Planning
                    </h6>
                </div>
                <ul>
                    <li>
                        Requirement Clarification
                    </li>
                    <li>
                        Design Scope Definition
                    </li>
                    <li>
                        Design Style and Aesthetic Decisions
                    </li>
                </ul>
            </div>
            <div className={styles.ul_div} style={{
                visibility: timer >= 2 ? "visible" : "hidden",
                opacity: timer >= 2 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div + " " + styles.right_align}>
                    <div className={styles.number_div}>
                        2
                    </div>
                    <h6>
                        Concept and<br/>Prototyping
                    </h6>
                </div>
                <ul>
                    <li>
                        Concept Evaluation
                    </li>
                    <li>
                        Wireframing and Prototyping
                    </li>
                    <li>
                        Client Feedback
                    </li>
                </ul>
            </div>

            <div className={styles.ul_div} style={{
                visibility: timer >= 3 ? "visible" : "hidden",
                opacity: timer >= 3 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div}>
                    <div className={styles.number_div}>
                        3
                    </div>
                    <h6>
                        Execution and<br/>Production
                    </h6>
                </div>

                <ul>
                    <li>
                        Quality Design Elements
                    </li>
                    <li>
                        File Preparation
                    </li>
                    <li>
                        Usability Testing
                    </li>
                </ul>
            </div>
            <div className={styles.ul_div} style={{
                visibility: timer >= 4 ? "visible" : "hidden",
                opacity: timer >= 4 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div + " " + styles.right_align}>
                    <div className={styles.number_div}>
                        4
                    </div>
                    <h6>
                        Review and<br/>Validation
                    </h6>
                </div>

                <ul>
                    <li>
                        Design Review
                    </li>
                    <li>
                        Proofreading
                    </li>
                    <li>
                        Validation Against Guidelines
                    </li>
                </ul>
            </div>
            <div className={styles.ul_div} style={{
                visibility: timer >= 5 ? "visible" : "hidden",
                opacity: timer >= 5 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div}>
                    <div className={styles.number_div}>
                        5
                    </div>
                    <h6>
                        Approval and<br/>Documentation
                    </h6>
                </div>
                <ul>
                    <li>
                        Client Presentation
                    </li>
                    <li>
                        Documentation and Style Guides
                    </li>
                    <li>
                        Archiving and Version Control
                    </li>
                </ul>
            </div>

        </section>
    );
};

export default MobileUiUxProcessSection;