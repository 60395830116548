import HeroSection from "../../component/organism/Home/HeroSection";
import AboutUsSection from "../../component/organism/Home/AboutUs";
import Services from "../../component/organism/Home/Services";
import CaseStudySection from "../../component/organism/Home/CaseStudy";
import FaqSection from "../../component/organism/Home/FAQ";
import ContactUsSection from "../../component/organism/Home/ContactUs";
import {useEffect} from "react";
import CustomNotificationBar from "../../component/atom/CustomNotificationBar";
import {useSelector} from "react-redux";

const Home = () => {

    useEffect(() => {
        window.scrollTo({top:0, left:0, behavior: "smooth"})
    }, []);

    const notifyBarOpen = useSelector(state => state.userNotification.notify.barOpen);

    return(
        <div>
            <HeroSection />
            <AboutUsSection />
            <Services />
            <CaseStudySection />
            <FaqSection />
            <ContactUsSection/>
            {notifyBarOpen &&
                <CustomNotificationBar/>
            }
        </div>
    );
};

export default Home;