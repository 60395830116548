import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import ceoImg from "../../../../assets/backgrounds/AboutUs/OurTeam/ceo.svg";
import ctoImg from "../../../../assets/backgrounds/AboutUs/OurTeam/cto.svg"
import qaImg from "../../../../assets/backgrounds/AboutUs/OurTeam/qa.svg";
import uiUxImg from "../../../../assets/backgrounds/AboutUs/OurTeam/uiux.svg";
import devImg from "../../../../assets/backgrounds/AboutUs/OurTeam/dev.svg";

const grdStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    gap: "5px",
}
const OurTeamSection = () => {

    return(
        <section  className={styles.main_section}>
            <Grid
                container
                spacing={{mobile: 1, tablet: 2, laptop: 2, desktop: 2}}
                alignItems="center"
                justifyContent="center"
                style={{
                    height: "100%",
                    margin: '0',
                    width: "100%",
                    zIndex: '4'
                }}
            >
                <Grid mobile={6} tablet={6} laptop={4} desktop={4} sx={grdStyle} className={styles.avatar_grid}>
                    <img src={ceoImg} alt={"avatar"} className={styles.avatar}/>
                    <h4>
                        HOVHANNES KASARJYAN
                    </h4>
                    <h5>
                        EXECUTIVE DIRECTOR (CEO)
                    </h5>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={4} desktop={4} sx={grdStyle} className={styles.avatar_grid}>
                    <img src={ctoImg} alt={"avatar"} className={styles.avatar}/>
                    <h4>
                        Gagik Vardanyan
                    </h4>
                    <h5>
                        technical DIRECTOR (CtO)
                    </h5>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={4} desktop={4} sx={grdStyle} className={styles.avatar_grid}>
                    <img src={qaImg} alt={"avatar"} className={styles.avatar}/>
                    <h4>
                        Ruben Aghekyan
                    </h4>
                    <h5>
                        Head of QA Department
                    </h5>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={4} desktop={4} sx={grdStyle} className={styles.avatar_grid}>
                    <img src={uiUxImg} alt={"avatar"} className={styles.avatar}/>
                    <h4>
                        Seda Mikayelyan
                    </h4>
                    <h5>
                        Head of design department
                    </h5>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={4} desktop={4} sx={grdStyle} className={styles.avatar_grid}>
                    <img src={devImg} alt={"avatar"} className={styles.avatar}/>
                    <h4>
                        Artur Abovyan
                    </h4>
                    <h5>
                        Head of Development
                    </h5>
                </Grid>
            </Grid>
            <div className={styles.section_heading}>
                <h3>meet our</h3>
                <h2>magical team</h2>
            </div>
        </section>
    );
};

export default OurTeamSection;