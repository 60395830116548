import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";

const ContactUsHeroSection = () => {
    return(
        <section className={styles.main_section}>
            <Grid
                container
                spacing={{mobile: 1, tablet: 2, laptop: 2, desktop: 2}}
                alignItems="center"
                justifyContent="center"
                style={{
                    height: "100%",
                    margin: '0',
                    width: "100%",
                    zIndex: '4'
                }}
            >
                <Grid mobile={12} tablet={12} laptop={12} desktop={12} className={styles.heading_grid}>
                    <li>contact</li>
                    <h4>
                        Get in Touch with <span className={"underline_text_mobile_orange"}> Spesna</span>
                    </h4>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={12} desktop={12} className={styles.paragraph_grid}>
                    <p>
                        Have questions or ready to start a project? We're here to help. Reach out to us using the contact
                        information below, and let's begin the journey towards your digital success.
                    </p>
                </Grid>
            </Grid>
        </section>
    )
};

export default ContactUsHeroSection;