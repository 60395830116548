import styles from "./style.module.scss";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const ServicesPagination = ({changeCurrentSection}) => {
    const [index, setIndex] = useState(1);

    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    useEffect(() => {
        switch (index) {
            case 1:
                return changeCurrentSection("development");
            case 2:
                return changeCurrentSection("qa");
            case 3:
                return changeCurrentSection("uiUx");
        }
    }, [index]);

    if (isMobile) {
        return (
            <div className={styles.pagination_main_div}>
                <div className={index === 1 ? styles.pagination_mobile + " " + styles.active : styles.pagination_mobile} onClick={() => {
                    setIndex(1);
                }}>
                    <div className={index === 1 ? styles.flex_row_active_mobile : styles.flex_row_mobile}>
                        <div className={styles.pagination_number_mobile}>
                            1
                        </div>
                        {index === 1 &&
                            <h3>DEVELOPMENT</h3>
                        }
                    </div>
                    {index === 1 &&
                        <p>Web development service involves the creation and maintenance of websites and web applications to meet specific client needs.</p>
                    }
                </div>
                <div className={index === 2
                    ?
                    styles.pagination_mobile + " " + styles.second_pagination + " " + styles.active
                    :
                    styles.pagination_mobile + " " + styles.second_pagination
                }
                     onClick={() => {
                         setIndex(2);
                     }}>
                    <div className={index === 2 ? styles.flex_row_active_mobile : styles.flex_row_mobile}>
                        <div className={styles.pagination_number_mobile}>
                            2
                        </div>
                        {index === 2 &&
                            <h3>QA</h3>
                        }
                    </div>
                    {index === 2 &&
                        <p>QA engineering service focuses on ensuring the quality and reliability of software and digital products through rigorous testing and quality assurance processes.</p>
                    }
                </div>
                <div className={index === 3 ? styles.third_pagination_mobile + " " + styles.active : styles.third_pagination_mobile}
                     onClick={() => {
                         setIndex(3);
                     }}>
                    <div className={index === 3 ? styles.flex_row_active_mobile : styles.flex_row_mobile}>
                        <div className={styles.pagination_number_mobile + " " + styles.number_third}>
                            3
                        </div>
                        {index === 3 &&
                            <h3>UI/UX DESIGN</h3>
                        }
                    </div>
                    {index === 3 &&
                        <p>Design service encompasses the artistic and functional design of visual elements, user interfaces, and user experiences to enhance the aesthetics and usability of digital and physical products.</p>
                    }
                </div>
            </div>
        );
    }
    return (
        <div className={styles.pagination_main_div}>
            <div className={index === 1 ? styles.pagination + " " + styles.active : styles.pagination} onClick={() => {
                setIndex(1);
            }}>
                <div className={styles.flex_row}>
                    <div className={styles.pagination_number}>
                        1
                    </div>
                    {index === 1 &&
                        <h3>DEVELOPMENT</h3>
                    }
                </div>
                {index === 1 &&
                    <p>  Web development service involves the creation and maintenance of websites and web applications to meet specific client needs.</p>
                }
            </div>
            <div className={index === 2
                ?
                styles.pagination + " " + styles.second_pagination + " " + styles.active
                :
                styles.pagination + " " + styles.second_pagination
            }
                 onClick={() => {
                     setIndex(2);
                 }}>
                <div className={styles.flex_row}>
                    <div className={styles.pagination_number}>
                        2
                    </div>
                    {index === 2 &&
                        <h3>QA</h3>
                    }
                </div>
                {index === 2 &&
                    <p>QA engineering service focuses on ensuring the quality and reliability of software and digital products through rigorous testing and quality assurance processes.</p>
                }
            </div>
            <div className={index === 3 ? styles.third_pagination + " " + styles.active : styles.third_pagination}
                 onClick={() => {
                     setIndex(3);
                 }}>
                <div className={styles.flex_row}>
                    <div className={styles.pagination_number + " " + styles.number_third}>
                        3
                    </div>
                    {index === 3 &&
                        <h3>UI/UX DESIGN</h3>
                    }
                </div>
                {index === 3 &&
                    <p>Design service encompasses the artistic and functional design of visual elements, user interfaces, and user experiences to enhance the aesthetics and usability of products.</p>
                }
            </div>
        </div>
    );
};

export default ServicesPagination;