import { createSlice } from '@reduxjs/toolkit';

const isMobileSlice = createSlice({
  name: 'mobileVersion',
  initialState: {
    isMobile:  window.innerWidth <= 1040,
  },
  reducers: {
    changeScreenType: (state, action) => {
        state.isMobile = action.payload;
    },
  },
});

export const { changeScreenType } = isMobileSlice.actions;

export default isMobileSlice.reducer;
