import styles from "./style.module.scss";
import {useSelector} from "react-redux";

const AboutUsHeroSection = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    return (
        <section className={styles.main_section}>
            <div className={styles.first_heading_div}>
                <h2 className={styles.section_heading + " " + styles.float_left}>
                    <span className={`
                    ${styles.section_heading} ${
                        isMobile
                            ?
                            ""
                            :
                            "underline_text_mobile_orange"
                    }`}>SPESNA I</span>S A
                </h2>
                {
                    isMobile &&
                    <div className={styles.underline_mobile}/>
                }
                <h2 className={styles.section_heading + " " + styles.float_right}>SOFTWARE</h2>
            </div>
            <div className={styles.second_heading_div}>
                <h2 className={styles.second_section_heading}>
                    ENGINEERING <br/> COMPANY
                </h2>
                {
                    !isMobile &&
                    <div className={styles.section_icon}/>
                }
            </div>
            {
                isMobile &&
                <div className={styles.section_icon}/>
            }
        </section>
    );
};

export default AboutUsHeroSection;