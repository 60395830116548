import {useSelector} from "react-redux";
import MobileQaProcessSection from "./Mobile";
import DesktopQaProcessSection from "./Desktop";

const QaProcessSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileQaProcessSection />
                :
                <DesktopQaProcessSection />
            }
        </>
    );
};

export default QaProcessSection;