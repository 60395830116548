import styles from "./style.module.scss";
import mobileImg from "../../../../../../assets/backgrounds/Services/UiUx/mobileImg.png";
import consultingImg from "../../../../../../assets/backgrounds/Services/UiUx/consultingImg.png";
import websiteImg from "../../../../../../assets/backgrounds/Services/UiUx/websiteImg.png";
import orangeLineImg from "../../../../../../assets/backgrounds/Services/BlueLine.svg";
import {useNavigate} from "react-router-dom";

const MobileServicesSection = () => {
    const navigate = useNavigate();
    return (
        <section className={styles.main_section}>
            <div className={styles.main_imgs_div}>
                <div className={styles.img_div}>
                    <img src={mobileImg} alt={"img"} className={styles.mobile_img}/>
                    <h4>MOBILE APP DESIGN</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={consultingImg} alt={"img"} className={styles.ui_ux_img}/>
                    <h4>UI/UX CONSULTING</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={websiteImg} alt={"img"} className={styles.website_img}/>
                    <h4>WEBSITE DESIGN</h4>
                </div>
            </div>
            <div className={styles.hashtag_ribbon_blue}>
                <span>GN</span>
                <span>#APP DESIGN</span>
                <span>#WIREFRAMING</span>
                <span>#PROTOTTYPING</span>
                <span>#INTERACTIVE DESIGN</span>
                <span>#SKETCHING</span>
                <span>#USER EXPERIENCE</span>
            </div>
            <div className={styles.section_paragraph_div}>
                <button className={styles.section_btn} onClick={() => {
                    navigate("/case_studies");
                }} >
                    VIEW portfolio
                    <img src={orangeLineImg} alt={"line"}/>
                </button>
            </div>
        </section>
    );
};

export default MobileServicesSection;