import Grid from "@mui/material/Unstable_Grid2";
import {useRef, useState} from "react";
import CloseIcon from '@mui/icons-material/Close';
import BackupIcon from '@mui/icons-material/Backup';
import styles from "../style.module.scss";
import axios from "axios";
import {useSelector} from "react-redux";

const ApplySection = ({description}) => {
    const [falseValue, setFalseValue] = useState(false);
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        coverLetter: '',
        file: [],
    })

    const updateData = (name, value) => {
        setData({
            ...data,
            [name]: value,
        })
    }

    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const inputFile = useRef(null);

    // for notification
    const handleResetFileInput = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    }

            // drag and drop
    const [fileName, setFileName] = useState('')

    const wrapperRef = useRef(null);

    const [fileList, setFileList] = useState([]);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            // Check the file type
            if (newFile.type === "application/pdf" ||
                newFile.type === "application/msword" ||
                newFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                setFileName(newFile.name);
                const updatedList = [...fileList, newFile];
                setFileList(updatedList);
                updateData("file", updatedList);
            } else {
                alert("Only PDF, DOC, or DOCX files are allowed.");
            }
        }
    }


    // const fileRemove = (file) => {
    //     const updatedList = [...fileList];
    //     updatedList.splice(fileList.indexOf(file), 1);
    //     setFileList(updatedList);
    // }


    const resetFormValues = () => {
        setData({
            firstName: '',
            lastName: '',
            email: '',
            coverLetter: '',
            file: []
        });
        setFileName("");
        setFileList([]);
        handleResetFileInput();
    }
    const SendMessage = async () => {
        const formData = new FormData();
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('coverLetter', data.coverLetter);
        formData.append('email', data.email);
        formData.append('file', data.file[0], data.file[0].name);
        try {
            await axios.post('https://spesna-backend.onrender.com/send', formData).then(() => {
                resetFormValues();
            });
        } catch (er) {
            // alert(er.response.data.msg);
            console.log(er)
        }
    };

    const isValidEmail = (mail) => {
        return /\S+@\S+\.\S+/.test(mail);
    }
    // for form submit
    const submitHandler = async (e) => {
        e.preventDefault();
        const containsFalsyValues = Object.values(data).some(item => !item || (Array.isArray(item) && item.length === 0));
        if (!containsFalsyValues && isValidEmail(data.email)) {
            await SendMessage(data)
        } else {
            setFalseValue(true);
        }
    };

    return (
        <Grid
            container
            direction={"column"}
            spacing={{mobile: 2, tablet: 2, laptop: 1, desktop: 2}}
            alignItems="center"
            wrap={false}
            flexWrap={"nowrap"}
            sx={{
                marginTop: isMobile ? "30px" : "unset"
            }}
            justifyContent={isMobile ? "flex-start" : "center"}
            className={styles.main_section}
        >

            <Grid mobile={12} tablet={12} laptop={6} desktop={6} data-aos="fade-up"
                  sx={{textAlign: "start"}}>
                <label
                    className={styles.label}
                >First Name <span className={styles.label_red_part}>*</span>
                </label><br/>
                <input
                    type="text"
                    name="firstName"
                    className={styles.input}
                    value={data.firstName}
                    style={{
                        border: falseValue && data.firstName.length === 0 ? "2px solid red" : "1px solid #000"
                    }}
                    onChange={(e) => {
                        updateData("firstName", e.target.value);
                    }}
                />
            </Grid>
            <Grid mobile={12} tablet={12} laptop={6} desktop={6} data-aos="fade-up" sx={{
                textAlign: "start",
                alignItems: "end",
            }}>
                <label
                    className={styles.label}
                >
                    Last Name <span className={styles.label_red_part}>*</span>
                </label><br/>
                <input
                    type="text"
                    name="lastName"
                    value={data.lastName}
                    className={styles.input}
                    onChange={(e) => {
                        updateData("lastName", e.target.value);
                    }}
                    style={{
                        border: falseValue && data.lastName.length === 0 ? "2px solid red" : "1px solid #000"
                    }}
                />
            </Grid>


            <Grid mobile={12} tablet={12} laptop={6} desktop={6} data-aos="fade-up" sx={{
                textAlign: "start"
            }}>
                <label
                    className={styles.label}
                >
                    Email Address <span className={styles.label_red_part}>*</span>
                </label><br/>
                <input
                    type="email"
                    name="user_email"
                    value={data.email}
                    className={styles.input}
                    onChange={(e) => {
                        updateData("email", e.target.value);
                    }}
                    style={{
                        border: (falseValue && data.email.length === 0) || (falseValue && !isValidEmail(data.email)) ? "2px solid red" : "1px solid #000"
                    }}
                />
            </Grid>
            <Grid mobile={12} tablet={8} laptop={6} desktop={6} sx={{
                justifyContent: "center",
                textAlign: "start"
            }}>
                <label
                    className={styles.label}
                >
                    Cover Letter <span className={styles.label_red_part}>*</span>
                </label><br/>
                <textarea
                    minrows={5}
                    onChange={(e) => {
                        updateData("coverLetter", e.target.value);
                    }}
                    aria-label="maximum height"
                    placeholder=""
                    name="message"
                    className={styles.text_area}
                    value={data.coverLetter}
                    style={{
                        border: falseValue && data.coverLetter.length === 0 ? "2px solid red" : "1px solid #000"
                    }}
                />
            </Grid>
            <Grid mobile={12} tablet={12} laptop={6} desktop={6} sx={{
                textAlign: "start"
            }}>
                <div
                    ref={wrapperRef}
                    className={styles.drop_file_input}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    style={{
                        border: falseValue && data.file.length === 0 ? "3px dashed red" : "3px dashed #FFFFFF"
                    }}
                >
                    <label htmlFor="input_file">
                        {fileName
                            ?
                            <div className={styles.drag_label}>
                                <span>{fileName}</span>
                                <button className={styles.close} onClick={() => {
                                    setFileName('');
                                    setFileList([]);
                                }}>
                                    <CloseIcon sx={{fill: "#FFF"}}/>
                                </button>

                            </div>
                            :
                            <div>
                                <div className={styles.drag_label}>
                                    <BackupIcon fontSize='large' sx={{
                                        fill: "#FFF",
                                    }}/>
                                    <span> Drag and drop your CV file here <span
                                        className={styles.label_red_part}>*</span></span><br/>
                                </div>
                            </div>}
                    </label>
                    <input
                        type="file"
                        accept=".doc, .docx, .pdf"
                        multiple={true}
                        name="cv-file"
                        className={styles.input_file}
                        onChange={onFileDrop}
                        ref={inputFile}
                    />
                </div>
            </Grid>
            <Grid mobile={12} tablet={12} laptop={12} desktop={12} sx={{
                display: "flex",
                justifyContent: "center"
            }}>
                <button className={styles.apply_btn} onClick={submitHandler}>
                    Next
                </button>
            </Grid>
        </Grid>
    );
};

export default ApplySection;