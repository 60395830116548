import {useParams} from "react-router-dom";
import {useEffect} from "react";
import MainCaseStudiesPage from "../../component/organism/CaseStudes/Main";
import MeatHouseDev from "../../component/organism/CaseStudes/MeatHouseDev";
import ContactUsSection from "../../component/organism/Home/ContactUs";
import MeatHouseDesign from "../../component/organism/CaseStudes/MeatHouseDesign";
import AntimalwareQa from "../../component/organism/CaseStudes/antimalwareQa";
import HealthcareQa from "../../component/organism/CaseStudes/HealthcareQa";

const CaseStudies = () => {
    const path = useParams().serviceType
    useEffect(() => {
        window.scrollTo({top:0, left:0, behavior: "smooth"})
    }, []);

    if (path === "meathouse_dev_solutions") {
        return (
            <>
                <MeatHouseDev />
            </>
        );
    } else if (path === "quality_assurance_antimalware") {
        return (
            <>
                <AntimalwareQa />
                <ContactUsSection />
            </>
        );
    }else if (path === "quality_assurance_healthcare") {
        return (
            <>
                <HealthcareQa />
                <ContactUsSection />
            </>
        );
    } else if (path === "meathouse_design_solutions"){
        return (
            <>
                <MeatHouseDesign />
            </>
        );
    }else{
        return (
            <>
                <MainCaseStudiesPage/>
                <ContactUsSection />
            </>
        );
    }
};

export default CaseStudies;