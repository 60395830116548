import styles from "./style.module.scss";

import orangeLineImg from "../../../../../assets/backgrounds/Services/OrangeLine.svg";

import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
const QaHeroSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    const navigate = useNavigate();
    return(
        <section className={styles.main_section}>
            {isMobile ?
            <h1 className={styles.section_heading}>
                Ensure Flawless<br/>Performance <br/>with Expert<br/>Quality<br/>Assurance and<br/><span className={"underline_text_mobile_orange"}>Testing</span>Solutions
            </h1>
                :
                <h1 className={styles.section_heading}>
                    Ensure Flawless<br/>Performance with Expert <br/> Quality Assurance and<br/><span className={"underline_text_mobile_orange"}>Testing Sol</span>utions
                </h1>
            }
            <button className={styles.section_btn} onClick={() => {
                navigate("/case_studies");
            }}>
                VIEW portfolio
                <img src={orangeLineImg} alt={"line"}/>
            </button>
        </section>
    );
};

export default QaHeroSection;