import { useEffect } from "react";
import HealthcareQaMobile from "./Mobile";
import HealthcareQaDesktop from "./Desktop";
import {useSelector} from "react-redux";

const HealthcareQa = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    return (
        <>
            {isMobile ?
                <HealthcareQaMobile />
                :
                <HealthcareQaDesktop />
            }
        </>
    );
};

export default HealthcareQa;