import styles from './style.module.scss';
import Grid from "@mui/material/Unstable_Grid2";
import CountUp from "react-countup";

const numberGridSx = {
    windth: '100%',
    display: "flex",
    justifyContent: "space-between" ,
    alignItems: "flex-start",
    gap: "3vw"
}
const DesktopAboutUsStatsSection = () => {
    return (
        <section className={styles.main_section}>
            <Grid
                container
                spacing={{mobile: 1, tablet: 2, laptop: 0, desktop: 0}}
                alignItems="center"
                justifyContent="center"
                style={{
                    height: "100%",
                    margin: '0',
                    width: "100%",
                }}
            >
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={numberGridSx}>
                    <div className={styles.main_number_div}>
                        <h3 className={styles.stats_heading}>
                            PROJECTS
                        </h3>
                        <CountUp
                            end={250}
                            duration={5}
                            suffix="+"
                            className={styles.animated_numbers}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        />
                    </div>
                    <p className={styles.section_paragraph}>
                        <span>We believe</span> that teamwork is the active driving force behind great software
                        development and partnerships.
                    </p>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={12} desktop={12}  sx={numberGridSx}>
                    <div className={styles.main_number_div}>
                        <h3 className={styles.stats_heading}>
                            INDUSTRIES
                        </h3>
                        <CountUp
                            end={7}
                            duration={5}
                            suffix="+"
                            className={styles.animated_numbers}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        />
                    </div>
                    <p className={styles.section_paragraph}>
                        <span>We increase and share</span> our expertise, experience, and commitment to growth to
                        fulfill clients’ results using the latest technology.
                    </p>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={12} desktop={12}  sx={numberGridSx}>
                    <div className={styles.main_number_div}>
                        <h3 className={styles.stats_heading}>
                            CUSTOMERS
                        </h3>
                        <CountUp
                            end={12}
                            duration={5}
                            suffix=""
                            className={styles.animated_numbers}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        />
                    </div>
                    <p className={styles.section_paragraph}>
                        <span>We adopt and solve</span> complex technical challenges no matter where, why, and when they
                        may arise due to a combination of extensive tech expertise, a stance of creative bravery, and an
                        uncompromising passion for our work.
                    </p>

                </Grid>
            </Grid>
        </section>
    );
};

export default DesktopAboutUsStatsSection;