import Grid from "@mui/material/Unstable_Grid2";
import styles from "./style.module.scss";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const MainCaseStudiesPage = () => {
    const [selectedService, setSelectedService] = useState("all");
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    return (
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                {isMobile
                    ?
                    <h1><span>case</span><br/>studies</h1>
                    :
                    <h1>case studies</h1>
                }

            </div>
            <Grid
                container
                direction={"row"}
                spacing={{mobile: 2, tablet: 2, laptop: 3, desktop: 3}}
                rowSpacing={{mobile: 6, tablet: 6, laptop: 3, desktop: 3}}
                alignItems="center"
                justifyContent="flex-start"
                className={styles.main_grid}
            >

                <Grid mobile={12} tablet={12} laptop={12} desktop={12}
                      className={styles.tool_grid}
                >
                    <h1>
                        Some recent work
                    </h1>
                    <ul className={styles.main_ul_slider_toggle}>
                        <li
                            className={selectedService === "all" ? styles.li_active : styles.li_disabled}
                            onClick={() => {
                                setSelectedService("all");
                            }}
                        >
                            <a href={() => false}>
                                All
                            </a>
                        </li>
                        <li
                            className={selectedService === "dev" ? styles.li_active : styles.li_disabled}
                            onClick={() => {
                                setSelectedService("dev");
                            }}
                        >
                            <a href={() => false}>
                                Web Development
                            </a>
                        </li>
                        <li
                            className={selectedService === "qa" ? styles.li_active : styles.li_disabled}
                            onClick={() => {
                                setSelectedService("qa");
                            }}
                        >
                            <a href={() => false}>
                                QA
                            </a>
                        </li>
                        <li
                            className={selectedService === "design" ? styles.li_active : styles.li_disabled}
                            onClick={() => {
                                setSelectedService("design");
                            }}
                        >
                            <a href={() => false}>
                                UI/UX design
                            </a>
                        </li>
                    </ul>
                </Grid>
                {(selectedService === "all" || selectedService === "dev") && 
                    <Grid mobile={12} tablet={12} laptop={8} desktop={8}
                        className={styles.project_grid}
                    >
                        <div className={styles.meat_web_main_div}>
                            <div className={styles.navigate_div + " " + styles.navigate_div_big}>
                                <h6>
                                    Web development solution for online meat store
                                </h6>
                                <Link to={"meathouse_dev_solutions"}>
                                    SEE DETAILS <hr/>
                                </Link>
                                <div className={styles.navigate_background_div + " " + styles.navigate_background_div_big}/>
                            </div>
                        </div>
                    </Grid>
                }
                 {(selectedService === "all" || selectedService === "design") && 
                    <Grid mobile={6} tablet={6} laptop={4} desktop={4}
                        className={styles.project_grid}
                    >
                        <div className={styles.meat_design_main_div}>
                            <div className={styles.navigate_div}>
                                <h6>
                                    Web development solution for online meat store
                                </h6>
                                <Link to={"meathouse_design_solutions"}>
                                    SEE DETAILS <hr/>
                                </Link>
                                <div className={styles.navigate_background_div}/>
                            </div>
                        </div>
                    </Grid>
                }
                {(selectedService === "all" || selectedService === "qa") && 
                 <Grid mobile={6} tablet={6} laptop={4} desktop={4}
                      className={styles.project_grid}
                >
                    <div className={styles.qa_project_main_div}>
                        <div className={styles.navigate_div}>
                            <h6>
                                Quality assurance solution
                            </h6>
                            <Link to={"quality_assurance_antimalware"}>
                                SEE DETAILS <hr/>
                            </Link>
                            <div className={styles.navigate_background_div}/>
                        </div>
                    </div>
                </Grid>
            }
                {(selectedService === "all" || selectedService === "qa") &&
                    <Grid mobile={12} tablet={12} laptop={8} desktop={8}
                          className={styles.project_grid}
                    >
                        <div className={styles.meat_web_main_div + " " + styles.qa_healthcare_bg}>
                            <div className={styles.navigate_div + " " + styles.navigate_div_big}>
                                <h6>
                                    Quality assurance solution
                                </h6>
                                <Link to={"quality_assurance_healthcare"}>
                                    SEE DETAILS <hr/>
                                </Link>
                                <div className={styles.navigate_background_div + " " + styles.navigate_background_div_big}/>
                            </div>
                        </div>
                    </Grid>
                }
            </Grid>
        </div>
    );
};

export default MainCaseStudiesPage;