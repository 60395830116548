import styles from "./style.module.scss";
import increaseIcon from "../../../../../assets/icons/Home/FaqSection/plus.png";
import decreaseIcon from "../../../../../assets/icons/Home/FaqSection/minus.svg";

import rectangle from "../../../../../assets/backgrounds/Home/FaqSection/RectangleWhite.svg";


const CustomFaqDropdown = ({activeQuestion, setActiveQuestion, number, mobile, question, answer}) => {
    if(mobile){
        return (
            <div className={
                activeQuestion === number
                    ?
                    styles.dropdown_mobile + " " + styles.dropdown_active_mobile
                    :
                    number === 4 || activeQuestion - 1 === number ? styles.dropdown_mobile + " " + styles.no_border : styles.dropdown_mobile
            }>
                <div className={styles.first_section_mobile}>
                    { activeQuestion === number &&
                        <img src={rectangle} alt={"icon"} className={styles.rectangle_absolute_mobile}/>
                    }
                    <h5>
                        0.{number}
                    </h5>
                    <div>
                        <h5>
                            {question}
                        </h5>
                        { activeQuestion === number &&

                            <p>
                                {answer}
                            </p>
                        }
                    </div>
                </div>
                {
                    activeQuestion === number
                        ?
                        <button className={styles.decrease_btn_mobile} onClick={() => {
                            setActiveQuestion(null)
                        }}>
                            <img src={decreaseIcon} alt={"-"} />
                        </button>
                        :
                        <button className={styles.increase_btn_mobile} onClick={() => {
                            setActiveQuestion(number)
                        }}>
                            <img src={increaseIcon} alt={"+"} />
                        </button>
                }

            </div>
        );
    }

    return (
        <div className={
            activeQuestion === number
            ?
            styles.dropdown + " " + styles.dropdown_active
            :
            number === 4 || activeQuestion - 1 === number ? styles.dropdown + " " + styles.no_border : styles.dropdown
        }>
            <div className={styles.first_section}>
                { activeQuestion === number &&
                    <img src={rectangle} alt={"icon"} className={styles.rectangle_absolute}/>
                }
                <h5>
                   0.{number}
                </h5>
                <div>
                    <h5>
                        {question}
                    </h5>
                    { activeQuestion === number &&

                        <p>
                            {answer}
                        </p>
                    }
                </div>
            </div>
            {
                activeQuestion === number
                    ?
                    <button className={styles.decrease_btn} onClick={() => {
                        setActiveQuestion(null)
                    }}>
                      <img src={decreaseIcon} alt={"-"} />
                    </button>
                    :
                    <button className={styles.increase_btn} onClick={() => {
                        setActiveQuestion(number)
                    }}>
                        <img src={increaseIcon} alt={"-"} />
                    </button>
            }

        </div>
    );
};

export default CustomFaqDropdown;