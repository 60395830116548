import {useSelector} from "react-redux";
import MobileServicesSection from "./Mobile";
import DesktopServicesSection from "./Desktop";


const ServicesSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileServicesSection />
                :
                <DesktopServicesSection />
            }
        </>
    );
};

export default ServicesSection;