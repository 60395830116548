import ContactUsSection from "../../component/organism/Home/ContactUs";
import CareersHeroSection from "../../component/organism/Careers/HeroSection";
import CareersSection from "../../component/organism/Careers/CareersSection";

const Careers = () => {
    return(
        <>
            <CareersHeroSection />
            <CareersSection/>
            <ContactUsSection />
        </>
    );
};

export default Careers;