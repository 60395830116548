import styles from "./style.module.scss";

import hashtags from "../../../../assets/backgrounds/AboutUs/Hiring/hashtagImg.png";
import pcImg from "../../../../assets/backgrounds/AboutUs/Hiring/pcImg.png";
import rectangle from "../../../../assets/backgrounds/AboutUs/Hiring/rectangle.svg";
import {useNavigate} from "react-router-dom";


const HiringSection = () =>{
    const navigate = useNavigate();

    return(
        <section className={styles.main_section}>
            <h1 className={`${styles.section_heading} underline_text_mobile_orange`}>#HIRING...</h1>
            <div className={styles.img_div}>
                <div className={styles.img_frame}>
                    <img src={rectangle} alt={"rectangle"} className={styles.rectangleImg}/>
                    <img src={pcImg} alt={"pc"} className={styles.pcImg}/>
                    <img src={hashtags} alt={"hashtag"} className={styles.hashtagsImg}/>
                </div>
            </div>
            <button className={styles.section_btn} onClick={() => {
                navigate("/careers");
            }}>
                LEARN more
            </button>
        </section>
    );
};

export default HiringSection;