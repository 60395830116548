import styles from "./style.module.scss";
import successIcon from "../../../assets/icons/NotificationBar/success.png";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {closeBar} from "../../../redux/features/notifyMessage";
import WarningIcon from '@mui/icons-material/Warning';

const CustomNotificationBar = () => {
    const dispatch = useDispatch();
    const notifyConf = useSelector(state => state.userNotification.notify);

    useEffect(() => {
        setTimeout(() => {
            dispatch(closeBar())
        }, 2000)
    }, [])

    return (
        <div className={styles.main_fixed_div}>
            {notifyConf.type === 'success'
                ?
                <div className={styles.notification_bar + " " + styles.success}>
                    <img src={successIcon} alt={"icon"}/>
                    <span>
                        {notifyConf.message}
                    </span>
                </div>
                :
                <div className={styles.notification_bar + " " + styles.warning}>
                    <WarningIcon
                        fontSize={"large"}
                        sx={{ color: "#730101 !important" }}
                    />
                    <span>
                        {notifyConf.message}
                    </span>
                </div>
            }

        </div>
    );
};

export default CustomNotificationBar;