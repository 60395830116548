import styles from "./style.module.scss";
import sectionBg from "../../../../../../assets/backgrounds/Home/HeroSection/qualityAssuranceBg.png"
const QualityAssurance = () => {
    return(
        <div className={styles.quality_assurance_section}>
            <img src={sectionBg} alt={"bg"} className={styles.section_bg}/>
        </div>
    );
};

export default QualityAssurance;