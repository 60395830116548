import {useSelector} from "react-redux";
import MobileProcessSection from "./Mobile";
import DesktopProcessSection from "./Desktop";


const ProcessSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileProcessSection />
                :
                <DesktopProcessSection />
            }
        </>
    );
};

export default ProcessSection;