import axios from "axios";
// https://spesna-backend.onrender.com/send
const SendMessage = async (form, callback) => {
    try {
        const res = await axios.post('https://spesna-backend.onrender.com/contact',form).then(()=>{
            callback()
        });
        return res;
    } catch (er){
        // alert(er.response.data.msg);
        console.log(er)
    }
};

export default SendMessage