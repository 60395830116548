import styles from "./style.module.scss";

import logo from "../../../assets/logo.png";
import phoneIcon from "../../../assets/icons/Layout/Footer/PhoneIcon.png";
import mailIcon from "../../../assets/icons/Layout/Footer/MailIcon.png";

const Footer = () => {
    return(
        <div className={styles.footer_section_background}>
            <div className={styles.footer_section}>
                <div className={styles.first_section}>
                    <div className={styles.contact_section_div}>
                        <div className={styles.phone_div}>
                            <img src={phoneIcon} alt={"icon"}/>
                            <span>+(374)77 73 33 73</span>
                        </div>
                        <div className={styles.phone_div}>
                            <img src={mailIcon} alt={"icon"}/>
                            <span>contact@spesna.com</span>
                        </div>
                    </div>
                    <img src={logo} alt={"logo"} className={styles.logo}/>
                </div>
                <h6>
                    © 2023 Spesna | <span>All rights reserved</span>
                </h6>
            </div>

        </div>
    );
};

export default Footer;
