import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import DefaultLayout from "./layouts/Default";
import {HomePage, AboutUsPage, ServicesPage, CaseStudies, ContactUs, Careers} from "./pages";

export const useRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<DefaultLayout/>}>
                    <Route exact index element={<HomePage/>}></Route>
                    <Route exact path={"/about_us"} element={<AboutUsPage/>}></Route>
                    <Route exact path={"/services/:serviceType"} element={<ServicesPage/>}></Route>
                    <Route exact path={"/case_studies"} element={<CaseStudies/>}></Route>
                    <Route exact path={"/case_studies/:serviceType"} element={<CaseStudies/>}></Route>
                    <Route exact path={"/contact_us"} element={<ContactUs/>}></Route>
                    <Route exact path={"/careers"} element={<Careers/>}></Route>
                    <Route exact path='*' element={<Navigate to="/"/>}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};


