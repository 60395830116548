import styles from "./style.module.scss";
import frontBack from "../../../../../../assets/backgrounds/Services/Dev/frontBack.png";
import database from "../../../../../../assets/backgrounds/Services/Dev/database.png";
import integration from "../../../../../../assets/backgrounds/Services/Dev/integration.png";
import orangeLineImg from "../../../../../../assets/backgrounds/Services/BlueLine.svg";
import {useNavigate} from "react-router-dom";

const DesktopDevServicesSection = () => {
    const navigate = useNavigate();

    return (
        <section className={styles.main_section}>
            <div className={styles.main_imgs_div}>
                <div className={styles.img_div}>
                    <img src={frontBack} alt={"img"} className={styles.mobile_img}/>
                    <h4>Frontend and Backend<br/>Development</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={database} alt={"img"} className={styles.ui_ux_img}/>
                    <h4>Database<br/>Management</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={integration} alt={"img"} className={styles.website_img}/>
                    <h4>API Development &<br/>Integration Services</h4>
                </div>
            </div>
            <div className={styles.hashtag_ribbon_blue}>
                <span>#WEB DEVELOPMENT</span>
                <span>#API</span>
                <span>#BACKEND</span>
                <span>#BUG</span>
                <span>#CSS</span>
                <span>#DEPLOYMENT</span>
                <span>#FRAMEWORKS</span>
                <span>#FRONTEND</span>
            </div>
            <div className={styles.section_paragraph_div}>
                <button className={styles.section_btn} onClick={() => {
                    navigate("/case_studies");
                }}>
                    VIEW portfolio
                    <img src={orangeLineImg} alt={"line"}/>
                </button>
            </div>
        </section>
    );
};

export default DesktopDevServicesSection;