import {useSelector} from "react-redux";
import DesktopNavbar from "./Desktop";
import MobileNavbar from "./Mobile";

const Navbar = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileNavbar />
                :
                <DesktopNavbar />
            }
        </>
    );
};

export default Navbar;