import styles from "./style.module.scss";

import orangeLineImg from "../../../../../assets/backgrounds/Services/OrangeLine.svg";

import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const DevHeroSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    const navigate = useNavigate();

    return (
        <section className={styles.main_section}>
            {isMobile ?
                <h1 className={styles.section_heading}>

                Transform Your<br/>OnlinePresence<br/>with Intuitive<br/>and Captivating<br/>Web<br/>
                    <span className={"underline_text_mobile_orange"}>Development</span>Solutions
                </h1>
                :
                <h1 className={styles.section_heading}>
                    Transform Your Online<br/>Presence with Intuitive<br/>and Captivating Web<br/>
                    <span className={"underline_text_mobile_orange"}>Development </span> Solutions
                </h1>
            }

            <button className={styles.section_btn} onClick={() => {
                navigate("/case_studies");
            }}>
                VIEW portfolio
                <img src={orangeLineImg} alt={"line"}/>
            </button>
        </section>
    );
};

export default DevHeroSection;