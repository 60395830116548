import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {useCallback, useEffect, useRef, useState} from "react";
import useIsVisible from "../../../../../Hooks/useIsVisible";
import {useSelector} from "react-redux";


const DevProcessSection = () => {
    const [startTimer, setStartTimer] = useState(false);
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [timer, setTimer] = useState(0)

    const sectionRef = useRef(null);

    const isVisible = useIsVisible(sectionRef);

    const rightMobileSx = isMobile ? {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
        }
        : {};

    const leftMobileSx = isMobile ? {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    } : {};


    const incrementTimer = useCallback(() => {
        setTimer((oldTimer) => oldTimer + 1)
    }, [])

    useEffect(() => {
        if(isVisible) setStartTimer(true)
    }, [isVisible])

    useEffect(() => {
        let timeoutFunction
        if (timer === 5) {
            return
        } else if (startTimer) {
            timeoutFunction = setInterval(incrementTimer, 700)
        }
        return () => clearInterval(timeoutFunction);
    }, [incrementTimer, timer, startTimer])

    return (
        <section className={styles.main_section}>
            {isMobile &&
                <div className={styles.position_relative}>
                    <div className={styles.full_stack_img}/>
                </div>
            }
            <Grid
                container
                rowSpacing={{mobile: 2, tablet: 2, laptop: 0, desktop: 0}}
                alignItems="center"
                justifyContent="flex-start"
                style={{
                    margin: 0,
                    width: isMobile ? "100%" : "100%",
                    zIndex: "100",
                    height: "100%",
                    overflowX: "hidden !important",
                }}
            >
                <Grid mobile={12} tablet={12} laptop={4} desktop={4}
                      sx={leftMobileSx}
                      style={{
                          visibility: timer >= 1 ? "visible" : "hidden",
                          opacity: timer >= 1 ? 1 : 0,
                          overflowX: "hidden !important",
                      }}>

                    <div ref={sectionRef} className={styles.step_heading}>
                        <h4>1</h4>
                        <h5>Requirements<br/>and Planning</h5>
                    </div>
                    <ul className={isMobile ? styles.ul_li + " " + styles.left_text_align : styles.ul_li}>
                        <li>
                            Requirement Gathering
                        </li>
                        <li>
                            Project Scope Definition
                        </li>
                        <li>
                            Resource Allocation
                        </li>
                    </ul>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={4} desktop={4} style={{
                    visibility: timer >= 2 ? "visible" : "hidden",
                    opacity: timer >= 2 ? 1 : 0,
                }} sx={rightMobileSx}>
                    <div className={styles.step_heading}>
                        <h4>2</h4>
                        <h5>Design and<br/>Development</h5>
                    </div>
                    <ul className={styles.ul_li}>
                        <li>
                            Front-End Development
                        </li>
                        <li>
                            Back-End Development
                        </li>
                        <li>
                            Quality Code Practices
                        </li>
                    </ul>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={4} desktop={4} style={{
                    visibility: timer >= 3 ? "visible" : "hidden",
                    opacity: timer >= 3 ? 1 : 0,
                }}
                      sx={leftMobileSx}
                >
                    <div className={styles.step_heading}>
                        <h4>3</h4>
                        <h5>Testing and<br/>Quality Assurance</h5>
                    </div>
                    <ul className={isMobile ? styles.ul_li + " " + styles.left_text_align : styles.ul_li}>
                        <li>
                            Functionality Testing
                        </li>
                        <li>
                            Compatibility Testing
                        </li>
                        <li>
                            Security Testing
                        </li>
                    </ul>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={4} desktop={4} style={{
                    visibility: timer >= 4 ? "visible" : "hidden",
                    opacity: timer >= 4 ? 1 : 0,
                }}
                      sx={rightMobileSx}
                >
                    <div className={styles.step_heading}>
                        <h4>4</h4>
                        <h5>Content and<br/>Data Management</h5>
                    </div>
                    <ul className={styles.ul_li}>
                        <li>
                            Content Validation
                        </li>
                        <li>
                            Data Integrity
                        </li>
                        <li>
                            Data Backup and Recovery Testing
                        </li>
                    </ul>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={4} desktop={4} style={{
                    visibility: timer >= 5 ? "visible" : "hidden",
                    opacity: timer >= 5 ? 1 : 0,
                }}
                >
                    <div className={styles.step_heading}>
                        <h4>5</h4>
                        <h5>Deployment and<br/>Maintenance</h5>
                    </div>
                    <ul className={isMobile ? styles.ul_li + " " + styles.left_text_align : styles.ul_li}>
                        <li>
                            Staging Environment Testing
                        </li>
                        <li>
                            Monitoring and Updates
                        </li>
                        <li>
                            User Feedback Integration
                        </li>
                    </ul>
                </Grid>
                {!isMobile &&
                    <div className={styles.full_stack_img}/>
                }
            </Grid>
        </section>
    );
};

export default DevProcessSection;