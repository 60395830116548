import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {useNavigate} from "react-router-dom";


const DesktopImportanceSection = () => {
    const navigate = useNavigate();
    return (
        <section className={styles.main_section}>
            <Grid
                container
                direction="column"
                rowSpacing={{mobile: 1, tablet: 2, laptop: 5, desktop: 5}}
                alignItems="center"
                justifyContent="end"
                columnGap={'3vh'}
                style={{
                    margin: 0,
                    columnGap: "3vh",
                    padding: "10px",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={{
                    display: "flex",
                    gap: "6vw"
                }}>
                    <div className={styles.group_div}>
                        <h4>
                            Meticulous Quality<br/>Assurance
                        </h4>
                        <div className={styles.orange_div + " " + styles.first}>
                            <p>
                                With meticulous attention to detail, our quality assurance ensures flawless testing,
                                guaranteeing that your digital products meet the highest standards of reliability
                                and performance.
                            </p>
                            <hr/>
                        </div>

                    </div>

                    <div className={styles.group_div}>
                        <h4>
                            User-Centric<br/>Testing
                        </h4>
                        <div className={styles.blue_div + " " + styles.second}>
                            <p>
                                Our user-centric testing approach goes beyond bug detection. It focuses on enhancing
                                the overall user experience, identifying and refining elements
                                that contribute to a seamless and enjoyable interaction with your digital solutions.
                            </p>
                            <hr/>
                        </div>

                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={{
                    display: "flex",
                    gap: "6vw"
                }}>
                    <div className={styles.group_div}>
                        <h4>
                            Continuous Improvement<br/>for Quality
                        </h4>
                        <div className={styles.blue_div + " " + styles.third}>
                            <p>
                                Embracing a culture of continuous improvement, our QA team evolves alongside technology,
                                adapting testing methodologies to deliver ever-improving quality for
                                your digital products, ensuring they stay ahead in a dynamic digital landscape.
                            </p>
                            <hr/>
                        </div>
                    </div>
                    <div className={styles.group_div}>
                        <h4>
                            Client-Centric Quality<br/>Partnerships
                        </h4>
                        <div className={styles.orange_div + " " + styles.fourth}>
                            <p>
                                In our client-centric approach to quality, we form partnerships that extend beyond
                                testing. We collaborate closely, understanding your unique goals to tailor our QA
                                services, ensuring the success of your digital products aligns with your vision.
                            </p>
                            <hr/>
                        </div>
                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "4vh",
                    padding: 0
                }}>
                    <button className={styles.section_btn} onClick={() => {
                        navigate("/contact_us");
                    }}>
                        Get in touch
                    </button>
                </Grid>
            </Grid>
        </section>
    );
};

export default DesktopImportanceSection;