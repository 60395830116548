import {useSelector} from "react-redux";
import DesktopHeroSection from "./Desktop";
import MobileHeroSection from "./Mobile";

const HomeHeroSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileHeroSection />
                :
                <DesktopHeroSection />
            }
        </>
    );
};

export default HomeHeroSection;