import styles from "./style.module.scss";
import Grid from '@mui/material/Unstable_Grid2'
import ServicesPagination from "../../../../atom/ServicesPagination";
import {useEffect, useState} from "react";
import orangeLineImg from "../../../../../assets/backgrounds/Services/OrangeLine.svg";
import {useNavigate} from "react-router-dom";

const DesktopServicesSection = () => {
    const [currentSection, setCurrentSection] = useState("development");
    const [sectionData, setSectionData] = useState({
        gif: styles.developmentGif,
        img1: styles.developmentImg1,
        img2: styles.developmentImg2
    });

    const navigate = useNavigate();

    const data = [
        {
            gif: styles.developmentGif,
            img1: styles.developmentImg1,
            img2: styles.developmentImg2,
            text: "Building Tomorrow's Web Today: \n" +
                "Your Vision, Our Expertise "
        },
        {
            gif: styles.qaGif,
            img1: styles.qaImg1,
            img2: styles.qaImg2,
            text: "Quality Beyond Measure: \n" +
                "Elevating Digital Products with QA Excellence"
        },
        {
            gif: styles.uiUxGif,
            img1: styles.uiUxImg1,
            img2: styles.uiUxImg2,
            text: "Where Art Meets Functionality: \n" +
                "Transformative Design Services"
        }
    ];

    useEffect(() => {
        setSectionData(currentSection === "development" ? data[0] : currentSection === "qa" ? data[1] : data[2]);
    }, [currentSection]);

    const handleNavigate = () => {
        if(currentSection === "development" ){
            navigate('/services/development');
        }else if(currentSection === "qa" ){
            navigate('/services/quality_assurance');
        }else{
            navigate('/services/design');
        }
    };

    return (
        <section className={styles.main_section}>
            <Grid
                container
                spacing={{mobile: 1, tablet: 2, laptop: 2, desktop: 3}}
                alignItems="end"
                justifyContent="center"
                className={styles.grid_container}
            >
                <Grid mobile={6} tablet={6} laptop={6} desktop={5} sx={{
                    maxWidth: '450px',
                    height: "100%"
                }}>
                    <div className={styles.first_section}>
                        <div className={styles.gif_frame}>
                            <div className={sectionData.gif + " " + styles.gif}/>
                        </div>
                        <div className={styles.flex_row}>
                            <div className={sectionData.img1 + " " + styles.img1}/>
                            <div className={currentSection !== "qa" ? styles.img2_frame + " " + styles.blue_border: styles.img2_frame}>
                                <div className={sectionData.img2 + " " + styles.img2}/>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={6} desktop={6}
                      sx={{
                          height: "100%"
                      }}
                >
                    <div className={styles.second_section}>
                        <div className={styles.paragraph_div}>
                            <p>
                                {sectionData.text}
                            </p>
                            <button className={styles.section_btn} onClick={handleNavigate}>
                                LEARN more
                                <img src={orangeLineImg} alt={"line"}/>
                            </button>
                        </div>
                        <ServicesPagination changeCurrentSection={(name) => {
                            setCurrentSection(name)
                        }}/>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
};

export default DesktopServicesSection;