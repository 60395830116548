import {useSelector} from "react-redux";
import DesktopDevServicesSection from "./Desktop";
import MobileDevServicesSection from "./Mobile";
const DevServicesSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileDevServicesSection />
                :
                <DesktopDevServicesSection />
            }
        </>
    );
};

export default DevServicesSection;