import styles from './style.module.scss';
import CountUp from "react-countup";

// const numberGridSx = {
//     windth: '100%',
//     display: "flex",
//     justifyContent: "space-between" ,
//     alignItems: "flex-start",
//     gap: "3vw"
// }
const MobileAboutUsStatsSection = () => {
    return (
        <section className={styles.main_section}>
            <div className={styles.stats_section}>
                <div className={styles.animated_numbers_div}>
                    <h3>
                        PROJECTS
                    </h3>
                    <CountUp
                        end={250}
                        duration={5}
                        suffix="+"
                        className={styles.animated_numbers}
                        enableScrollSpy={true}
                        scrollSpyOnce={true}
                    />
                </div>

                <div className={styles.flex_row_div}>
                    <div className={styles.animated_numbers_div}>
                        <h3>
                            CUSTOMERS
                        </h3>
                        <CountUp
                            end={12}
                            duration={5}
                            suffix=""
                            className={styles.animated_numbers}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        />
                    </div>
                    <div className={styles.animated_numbers_div}>
                        <h3>
                            INDUSTRIES
                        </h3>
                        <CountUp
                            end={7}
                            duration={5}
                            suffix="+"
                            className={styles.animated_numbers}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        />
                    </div>
                </div>
            </div>
            <p className={styles.section_paragraph}>
                <span>We believe</span> that teamwork is the active driving force behind great software
                development and partnerships.
            </p>
            <p className={styles.section_paragraph}>
                <span>We adopt and solve</span> complex technical challenges no matter where, why, and when they
                may arise due to a combination of extensive tech expertise, a stance of creative bravery, and an
                uncompromising passion for our work.
            </p>
            <p className={styles.section_paragraph}>
                <span>We increase and share</span> our expertise, experience, and commitment to growth to
                fulfill clients’ results using the latest technology.
            </p>
        </section>
    );
};

export default MobileAboutUsStatsSection;