import styles from "./style.module.scss";
import logo from "../../../../assets/logo.png";
import menuIcon from "../../../../assets/icons/Layout/Navbar/Mobile/MenuIcon.svg";
import {useLocation, useNavigate} from "react-router-dom";
import MobileSideBar from "../../../../component/molecul/Layout/SideBar/Mobile";
import {useState} from "react";

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    return (
        <header className={styles.main_navbar_div}>
            <img src={logo} alt={"logo"} className={styles.logo} onClick={() => {
                 if(location.pathname === "/"){
                    window.scrollTo({top:0, left: 0, behavior: "smooth"});
                }else {
                    navigate("/");
                }
            }}/>
            <button className={styles.navbar_btn} onClick={() => {
                setIsSidebarOpen(!isSidebarOpen);
            }}>
                <img src={menuIcon} alt={"logo"}/>
            </button>
            <MobileSideBar isOpen={isSidebarOpen} closeSidebar={() => {
                setIsSidebarOpen(false)
            }}/>
        </header>
    );
};

export default Navbar;