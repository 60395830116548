import {useSelector} from "react-redux";
import MobileCaseStudySection from "./Mobile";
import DesktopCaseStudySection from "./Desktop";

const CaseStudySection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileCaseStudySection />
                :
                <DesktopCaseStudySection/>
            }
        </>
    );
};

export default CaseStudySection;