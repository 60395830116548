import styles from "./style.module.scss";
import {useState} from "react";
import navigateIcon from "../../../../../assets/icons/Layout/Navbar/Dropdown/navigateIcon.svg"
import {useNavigate} from "react-router-dom";

const NavbarDropdown = ({openDropdown, closeDropdown}) => {
    const [currentRectangle, setCurrentRectangle] = useState('');
    const navigate = useNavigate();
    return (
        <div className={styles.dropdown} onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
            <div
                className={styles.rectangle_div + ' ' + styles.webBg}
                onMouseEnter={() => {
                    setCurrentRectangle("web");
                }}
                onMouseLeave={() => {
                    setCurrentRectangle("");
                }}
            >
                {currentRectangle === "web" ?
                    <div
                        onClick={() => {
                            navigate("/services/development");
                            closeDropdown();
                        }}
                        className={styles.rectangle_inner_div}>
                        <h3>
                            Web Development
                        </h3>
                        <p>
                            Establishes online presence, reaches wider audience,
                            provides essential services.
                        </p>
                        <button className={styles.navigate_btn} onClick={() => {
                            closeDropdown();
                            navigate("/services/development");
                        }}>
                            <img src={navigateIcon} alt={"icon"}/>
                        </button>
                    </div>
                    :
                    <h3>
                        Web Development
                    </h3>
                }
            </div>
            <div
                className={styles.rectangle_div + ' ' + styles.qaBg}
                onMouseEnter={() => {
                    setCurrentRectangle("qa");
                }}
                onMouseLeave={() => {
                    setCurrentRectangle("");
                }}
            >
                {currentRectangle === "qa" ?
                    <div
                        onClick={() => {
                            navigate("/services/quality_assurance");
                            closeDropdown();
                        }}
                        className={styles.rectangle_inner_div}
                    >
                        <h3>
                            QUALITY ASSURANCE
                        </h3>
                        <p>
                            Ensures reliable products, boosts customer satisfaction and loyalty.
                        </p>
                        <button className={styles.navigate_btn}>
                            <img src={navigateIcon} alt={"icon"}/>
                        </button>
                    </div>
                    :
                    <h3>
                        QUALITY ASSURANCE
                    </h3>
                }
            </div>
            <div
                className={styles.rectangle_div + ' ' + styles.uiUxBg}
                onMouseEnter={() => {
                    setCurrentRectangle("uiUx");
                }}
                onMouseLeave={() => {
                    setCurrentRectangle("");
                }}
            >
                {currentRectangle === "uiUx" ?
                    <div
                        onClick={() => {
                            navigate("/services/design");
                            closeDropdown();
                        }}
                        className={styles.rectangle_inner_div}
                        style={{
                            margin: '-20px 0 0 30px'
                        }}
                    >
                        <h3>
                            UI/UX DESIGN
                        </h3>
                        <p>
                            Impacts user experience, engagement, conversions,
                            and brand perception for business success.
                        </p>
                        <button className={styles.navigate_btn}>
                            <img src={navigateIcon} alt={"icon"}/>
                        </button>
                    </div>
                    :
                    <h3>
                        UI/UX DESIGN
                    </h3>
                }
            </div>
        </div>
    );
};

export default NavbarDropdown;