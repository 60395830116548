import styles from "./style.module.scss";
import orangeLineImg from "../../../../../../assets/backgrounds/Services/OrangeLine.svg";
import {useNavigate} from "react-router-dom";


const MobileImportanceSection = () => {
    const navigate = useNavigate();
    return (
        <section className={styles.main_section}>
            <div className={styles.group_div}>
                <h4>
                    Creative Excellence
                </h4>
                <div className={styles.blue_div + " " + styles.first}>
                    <p>
                        Meticulously crafted design, visually stunning, and deeply meaningful—our commitment to
                        creative excellence ensures your brand makes a lasting impact.
                    </p>
                    <hr/>
                </div>

            </div>

            <div className={styles.group_div}>
                <h4>
                    User-Centric Aesthetics
                </h4>
                <div className={styles.orange_div + " " + styles.second}>
                    <p>
                        Designs that effortlessly resonate. We craft visually appealing solutions with a sharp
                        focus on user experience, ensuring seamless connections between your brand and your audience.
                    </p>
                    <hr/>
                </div>

            </div>
            <div className={styles.group_div}>
                <h4>
                    Continuous Innovation<br/>in Design
                </h4>
                <div className={styles.orange_div + " " + styles.third}>
                    <p>
                        Innovative designs that set trends and captivate. Staying on the cutting edge,
                        we anticipate future aesthetics, keeping your brand ahead in the dynamic digital landscape.
                    </p>
                    <hr/>
                </div>
            </div>
            <div className={styles.group_div}>
                <h4>
                    Client-Centric<br/>Collaboration
                </h4>
                <div className={styles.blue_div + " " + styles.fourth}>
                    <p>
                        Partners in design success through close collaboration. We work hand-in-hand, deeply
                        understanding your brand identity to create visuals that authentically tell your unique story.
                    </p>
                    <hr/>
                </div>
            </div>


            <button className={styles.section_btn} onClick={() => {
                navigate("/contact_us");
            }}>
                GET IN touch
                <img src={orangeLineImg} alt={"line"}/>
            </button>
        </section>
    );
};

export default MobileImportanceSection;