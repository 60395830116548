import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {useNavigate} from "react-router-dom";


const DesktopImportanceSection = () => {
    const navigate = useNavigate();

    return (
        <section className={styles.main_section}>
            <Grid
                container
                direction="column"
                rowSpacing={{mobile: 1, tablet: 2, laptop: 5, desktop: 5}}
                alignItems="center"
                justifyContent="end"
                wrap="nowrap"
                columnGap={'3vh'}
                style={{
                    margin: 0,
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={{
                    display: "flex",
                    gap: "6vw"
                }}>
                    <div className={styles.group_div}>
                        <h4>
                            User-Centric<br/>Innovation
                        </h4>
                        <div className={styles.blue_div + " " + styles.first}>
                            <p>
                                We innovate with the user in mind, constantly seeking new ways to enhance online
                                experiences. From intuitive interfaces to seamless interactions, our commitment
                                to user-centric design ensures that every digital solution we create leaves a
                                lasting impression on your audience.
                            </p>
                            <hr/>
                        </div>

                    </div>

                    <div className={styles.group_div}>
                        <h4>
                            Continuous Improvement<br/>for Impact
                        </h4>
                        <div className={styles.orange_div + " " + styles.second}>
                            <p>
                                We believe in the power of continuous improvement to make a meaningful impact.
                                Our team consistently refines and evolves web solutions, staying attuned to
                                the latest trends and technologies. This dedication ensures that your online
                                presence remains dynamic, engaging, and ahead of the curve.
                            </p>
                            <hr/>
                        </div>

                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={{
                    display: "flex",
                    gap: "6vw"
                }}>
                    <div className={styles.group_div}>
                        <h4>
                            Relentless Quality in<br/>Every Detail
                        </h4>
                        <div className={styles.orange_div + " " + styles.third}>
                            <p>
                                Quality is not just a standard; it's our commitment to excellence. We meticulously
                                craft every detail of our web solutions to deliver an unparalleled user experience.
                                From pixel-perfect design to flawless functionality,
                                our relentless pursuit of quality ensures your digital presence stands out.
                            </p>
                            <hr/>
                        </div>
                    </div>
                    <div className={styles.group_div}>
                        <h4>
                            Client Success Through<br/>Collaboration
                        </h4>
                        <div className={styles.blue_div + " " + styles.fourth}>
                            <p>
                                We view client success as a collaborative journey. By deeply understanding your goals
                                and challenges, we work hand-in-hand with you to create web solutions that go beyond expectations.
                                Your improved online presence is not just a project; it's a testament
                                to our collaborative commitment to your success.
                            </p>
                            <hr/>
                        </div>
                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "4vh",
                    padding: 0
                }}>
                    <button className={styles.section_btn} onClick={() => {
                        navigate("/contact_us");
                    }}>
                        Get in touch
                    </button>
                </Grid>
            </Grid>
        </section>
    );
};

export default DesktopImportanceSection;