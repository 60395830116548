import {createSlice} from '@reduxjs/toolkit';

const notifyMessageSlice = createSlice({
  name: 'warningMessage',
  initialState: {
    notify: {
      barOpen: false,
      message: '',
      type: '',
    },
  },
  reducers: {
    notifyUser: (state, action) => {
        state.notify = action.payload;
    },
    closeBar: (state) => {
      state.notify = {
        barOpen: false,
        message: '',
        type: '',
      };
    },
  },
});

export const { notifyUser,closeBar } = notifyMessageSlice.actions;

export default notifyMessageSlice.reducer;
