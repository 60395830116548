import {combineReducers, createStore} from '@reduxjs/toolkit';
import screenReducer from '../features/isMobileSlice';
import notifyUserReducer from "../features/notifyMessage";

const rootReducer = combineReducers({
    screenVersion: screenReducer,
    userNotification: notifyUserReducer
});
const store = createStore(rootReducer);

export default store;