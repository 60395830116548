import Footer from "./Footer";
import Navbar from "./Navbar";
import {Outlet} from "react-router-dom";

const DefaultLayout = () => {

    return(
        <div style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }}>
            <Navbar/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default DefaultLayout;