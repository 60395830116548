import styles from "./style.module.scss";
import UnitImg from "../../../../../../assets/backgrounds/Services/Qa/UnitImg.png";
import SystemImg from "../../../../../../assets/backgrounds/Services/Qa/SystemImg.png";
import InterfaceImg from "../../../../../../assets/backgrounds/Services/Qa/InterfaceImg.png";
import RegressionImg from "../../../../../../assets/backgrounds/Services/Qa/RegressionImg.png";
import AcceptanceImg from "../../../../../../assets/backgrounds/Services/Qa/AcceptanceImg.png";
import IntegrationImg from "../../../../../../assets/backgrounds/Services/Qa/IntegrationImg.png";

const DesktopServicesSection = () => {
    return (
        <section className={styles.main_section}>
            <div className={styles.main_imgs_div}>
                <div className={styles.img_div}>
                    <img src={UnitImg} alt={"img"} />
                    <h4>Unit testing</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={SystemImg} alt={"img"} />
                    <h4>System testing</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={InterfaceImg} alt={"img"} />
                    <h4>Interface Testing</h4>
                </div>
            </div>
            <div className={styles.main_imgs_div}>
                <div className={styles.img_div}>
                    <img src={RegressionImg} alt={"img"}/>
                    <h4>Regression testing</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={AcceptanceImg} alt={"img"} />
                    <h4>user Acceptance Testing</h4>
                </div>
                <div className={styles.img_div}>
                    <img src={IntegrationImg} alt={"img"} />
                    <h4>Integration testing</h4>
                </div>
            </div>
        </section>
    );
};

export default DesktopServicesSection;