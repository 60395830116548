import {useSelector} from "react-redux";
import MobileFaqSection from "./Mobile";
import DesktopFaqSection from "./Desktop";

const FaqSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileFaqSection />
                :
                <DesktopFaqSection />
            }
        </>
    );
};

export default FaqSection;