import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import jobData from "../../../../assets/lang/en/Careers/en.json";
import CareersPopUp from "../../../molecul/Careers/PopUp";
import {useEffect, useState} from "react";

const CareersSection = () => {
    const [selectedJobDescription, setSelectedJobDescription] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        window.scrollTo(0,0)
    }, []);

    return (
        <Grid
            container
            component={"section"}
            direction={"row"}
            spacing={{mobile: 2, tablet: 1, laptop: 3, desktop: 3}}
            alignItems="center"
            justifyContent="space-between"
            className={styles.main_section}
        >
            {
                jobData.map((jobDetails, id) => {
                    return (
                        <Grid mobile={12} tablet={12} laptop={6} desktop={6} key={id}
                            sx={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <div className={styles.career_div_bg}>
                                <Grid
                                    container
                                    direction={"row"}
                                    spacing={{mobile: 0, tablet: 0, laptop: 3, desktop: 3}}
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        width: "60%"
                                    }}
                                >
                                    <Grid mobile={12} tablet={12} laptop={12} desktop={12} className={styles.job_name}>
                                        {jobDetails.jobName}
                                    </Grid>
                                    <Grid mobile={5} tablet={5} laptop={6} desktop={5} className={styles.job_type}>
                                        {jobDetails.jobType}
                                    </Grid>
                                    <Grid mobile={6} tablet={6} laptop={6} desktop={5}>
                                        <button className={styles.details_btn} onClick={() => {
                                            setSelectedJobDescription(jobDetails.jobDescription);
                                            setIsOpen(true);
                                        }}>
                                            VIEW details
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    )
                })
            }
            {
                isOpen &&
                <CareersPopUp jobDescription={selectedJobDescription} close={()=> {
                    setIsOpen(false);
                }}/>
            }
        </Grid>
    );
};

export default CareersSection;