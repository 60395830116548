import styles from "./style.module.scss";

import phoneBg from "../../../../../assets/backgrounds/Home/Study/qaSolutionsBg.webp";
import htmlTagIcon from "../../../../../assets/icons/Home/StudySection/htmlTagIcon.svg";
import finishIcon from "../../../../../assets/icons/Home/StudySection/finishIcon.svg";
import laptop1Icon from "../../../../../assets/icons/Home/StudySection/laptop1Icon.png";
import laptop2Icon from "../../../../../assets/icons/Home/StudySection/laptop2Icon.png";
import compilerIcon from "../../../../../assets/icons/Home/StudySection/compilerIcon.svg";
import toggleIcon from "../../../../../assets/icons/Home/StudySection/toggle.svg";
import {useNavigate} from "react-router-dom";

const MobileCaseStudySection = () => {
    const navigate = useNavigate();

    return (
        <section className={styles.main_section}>
            <div className={styles.section_heading}>
                <h3>CASESTUDY</h3>
            </div>
            <div className={styles.solutions_div}>
                <div className={styles.div_heading}>
                    <h6>case studies</h6>
                    <h3>Web development<br/>Solutions</h3>
                </div>
                <div className={styles.heading_flex_column}>
                    <div className={styles.heading_flex_row + " " + styles.first_row_div}>
                        <img src={htmlTagIcon} alt={"img"} className={styles.html_icon}/>
                        <div className={styles.skill_icon_first}>full stack <img src={toggleIcon} alt={"img"}
                                                                                 className={styles.toggleIcon}/></div>
                        <img src={finishIcon} alt={"img"} className={styles.finish_icon}/>
                    </div>
                    <div className={styles.heading_flex_row}>
                        <div className={styles.skill_icon_second}>Frontend</div>
                        <img src={laptop1Icon} alt={"img"} className={styles.laptop1_icon}/>
                        <div className={styles.skill_icon_second}>Backend</div>
                    </div>
                    <div className={styles.heading_flex_row}>
                        <img src={laptop2Icon} alt={"img"} className={styles.laptop2_icon}/>
                        <div className={styles.skill_icon_third}>DEVOPS</div>
                        <img src={compilerIcon} alt={"img"} className={styles.compiler_icon}/>
                    </div>
                </div>
            </div>
            <div className={styles.section_btn_div}>
                <button className={styles.section_btn} onClick={() => {
                    navigate("/case_studies");
                }}>
                    LEARN more
                </button>
            </div>
            <img src={phoneBg} alt={"phone"} className={styles.phone_bg}/>
        </section>
    );
};

export default MobileCaseStudySection;