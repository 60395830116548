import styles from "./style.module.scss";
import {useCallback, useEffect, useRef, useState} from "react";
import useIsVisible from "../../../../../../Hooks/useIsVisible";


const getCurrentDimension = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
};
const MobileQaProcessSection = () => {
    const [startTimer, setStartTimer] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const [timer, setTimer] = useState(0)

    const sectionRef = useRef(null);

    const isVisible = useIsVisible(sectionRef);


    const incrementTimer = useCallback(() => {
        setTimer((oldTimer) => oldTimer + 1)
    }, [])

    useEffect(() => {
        if(isVisible) setStartTimer(true)
    }, [isVisible])

    useEffect(() => {
        let timeoutFunction
        if (timer === 5) {
            return
        } else if (startTimer) {
            timeoutFunction = setInterval(incrementTimer, 700)
        }
        return () => clearInterval(timeoutFunction);
    }, [incrementTimer, timer, startTimer])


    // useEffect(() => {
    //     console.log(rangeNumber)
    //     if(rangeNumber === 5){
    //         clearInterval(sectionInterval)
    //     }
    // }, [rangeNumber]);
    return (
        <section className={styles.main_section}>
            <div className={styles.ul_div} ref={sectionRef} style={{
                visibility: timer >= 1 ? "visible" : "hidden",
                opacity: timer >= 1 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div}>
                    <div className={styles.number_div}>
                        1
                    </div>
                    <h6>
                        Process Definition
                    </h6>
                </div>
                <ul>
                    <li>
                        Requirements Analysis
                    </li>
                    <li>
                        Workflow Design
                    </li>
                    <li>
                        Standard Operating Procedures (SOPs)
                    </li>
                </ul>
            </div>
            <div className={styles.ul_div} style={{
                visibility: timer >= 2 ? "visible" : "hidden",
                opacity: timer >= 2 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div + " " + styles.right_align}>
                    <div className={styles.number_div}>
                        2
                    </div>
                    <h6>
                        Quality Planning
                    </h6>
                </div>
                <ul>
                    <li>
                        Test Strategy
                    </li>
                    <li>
                        Risk Assessment
                    </li>
                    <li>
                        Resource Allocation
                    </li>
                </ul>
            </div>

            <div className={styles.ul_div} style={{
                visibility: timer >= 3 ? "visible" : "hidden",
                opacity: timer >= 3 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div}>
                    <div className={styles.number_div}>
                        3
                    </div>
                    <h6>
                        Execution and<br/>Monitoring
                    </h6>
                </div>

                <ul>
                    <li>
                        Testing and Inspection
                    </li>
                    <li>
                        Data Collection
                    </li>
                    <li>
                        Continuous Monitoring
                    </li>
                </ul>
            </div>
            <div className={styles.ul_div} style={{
                visibility: timer >= 4 ? "visible" : "hidden",
                opacity: timer >= 4 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div + " " + styles.right_align}>
                    <div className={styles.number_div}>
                        4
                    </div>
                    <h6>
                        Defect Identification
                    </h6>
                </div>

                <ul>
                    <li>
                        Defect Logging
                    </li>
                    <li>
                        Root Cause Analysis
                    </li>
                    <li>
                        Corrective Action
                    </li>
                </ul>
            </div>
            <div className={styles.ul_div} style={{
                visibility: timer >= 5 ? "visible" : "hidden",
                opacity: timer >= 5 ? 1 : 0,
            }}>
                <div className={styles.heading_number_div}>
                    <div className={styles.number_div}>
                        5
                    </div>
                    <h6>
                        Process Improvement
                    </h6>
                </div>
                <ul>
                    <li>
                        Feedback Integration
                    </li>
                    <li>
                        Process Optimization
                    </li>
                    <li>
                        Training and Skill Development
                    </li>
                </ul>
            </div>

        </section>
    );
};

export default MobileQaProcessSection;