import styles from "./style.module.scss";

import blueLineImg from "../../../../../assets/backgrounds/Services/BlueLine.svg";

import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
const DesignHeroSection = () => {
    const navigate = useNavigate();
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return(
        <section className={styles.main_section}>
            {isMobile ?
                <h1 className={styles.section_heading}>
                    Elevate Your<br/>Business with<br/>Intuitive and<br/>Engaging UI/UX<br/><span className={"underline_text_orange"}>Design </span> Solutions
                </h1>
                :
                <h1 className={styles.section_heading}>
                    Elevate Your Business<br/>withIntuitive and<br/>Engaging <span className={"underline_text_mobile_orange"}>UI/UX Design</span><br/>Solutions
                </h1>
            }
            <button className={styles.section_btn} onClick={() => {
                navigate("/case_studies");
            }}>
                VIEW portfolio
                <img src={blueLineImg} alt={"line"}/>
            </button>
        </section>
    );
};

export default DesignHeroSection;