import Grid from "@mui/material/Unstable_Grid2";
import styles from "../style.module.scss";

const DescriptionSection = ({description, changeSection}) => {
    return (
        <Grid
            container
            direction={"row"}
            spacing={{mobile: 2, tablet: 2, laptop: 1, desktop: 2}}
            alignItems="flex-start"
            justifyContent="space-between"
            className={styles.main_section}
        >
            <Grid mobile={12} tablet={6} laptop={6} desktop={6}>
                <ul className={styles.description_ul}>
                    <li>
                        {description.jobTitle}
                    </li>
                    <li>
                        {description.location}
                    </li>
                    <li>
                        {description.jobType}
                    </li>
                    <li>
                        {description.experience}
                    </li>
                    <li>
                        {description.aboutUs}
                    </li>
                    <li>
                        {description.aboutUs}
                    </li>
                </ul>
            </Grid>
            <Grid mobile={12} tablet={6} laptop={6} desktop={6}>
                <ul className={styles.description_ul}>
                    <li>
                        {description.responsibilities}
                    </li>
                    <li>
                        {description.qualifications}
                    </li>
                </ul>
            </Grid>
            <Grid mobile={12} tablet={12} laptop={12} desktop={12} className={styles.send_help}>
                <p>
                    Please send your CV to contact@spesna.com, and before applying, kindly review the must-have
                    requirements mentioned above, and submit your CV in English, preferably in PDF format.
                </p>
            </Grid>
            <Grid mobile={12} tablet={12} laptop={12} desktop={12} className={styles.send_help}>
                <button className={styles.apply_btn} onClick={() => {
                    changeSection(2)
                }}>
                    Next
                </button>
            </Grid>
        </Grid>
    );
};

export default DescriptionSection;