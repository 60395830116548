import styles from "./style.module.scss";
import Grid from "@mui/material/Unstable_Grid2";
import {useCallback, useEffect, useRef, useState} from "react";
import useIsVisible from "../../../../../../Hooks/useIsVisible";

const spaceBetweenSx = {
    display: "flex",
    justifyContent: "space-between",
    height: "50px"
}
const getCurrentDimension = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
};
const DesktopQaProcessSection = () => {
    const [startTimer, setStartTimer] = useState(false);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const [timer, setTimer] = useState(0)

    const sectionRef = useRef(null);

    const isVisible = useIsVisible(sectionRef);


    const incrementTimer = useCallback(() => {
        setTimer((oldTimer) => oldTimer + 1)
    }, [])

    useEffect(() => {
        if(isVisible) setStartTimer(true)
    }, [isVisible])

    useEffect(() => {
        let timeoutFunction
        if (timer === 5) {
            return
        } else if (startTimer) {
            timeoutFunction = setInterval(incrementTimer, 700)
        }
        return () => clearInterval(timeoutFunction);
    }, [incrementTimer, timer, startTimer])

    // useEffect(() => {
    //     console.log(rangeNumber)
    //     if(rangeNumber === 5){
    //         clearInterval(sectionInterval)
    //     }
    // }, [rangeNumber]);
    return (
        <section className={styles.main_section}>
            <Grid
                container
                direction="column"
                rowSpacing={{mobile: 1, tablet: 2, laptop: 0, desktop: 0}}
                alignItems="center"
                justifyContent="flex-end"
                flexWrap={"nowrap"}
                style={{
                    margin: 0,
                    width: "100%",
                    zIndex: "100",
                    height: "100%"
                }}
            >
                <Grid mobile={6} tablet={6} laptop={3} desktop={3} sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "25px"
                }}>
                    <div className={styles.heading_number_div_first}  style={{
                        visibility: timer >= 3 ? "visible" : "hidden",
                        opacity:  timer >= 3 ? 1 : 0,
                    }}>
                        <h6>Execution and Monitoring</h6>
                        <div className={styles.number_div}>
                            3
                        </div>
                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={4} desktop={4} sx={spaceBetweenSx}>
                    <div className={styles.heading_number_div}  style={{
                        visibility: timer >= 2 ? "visible" : "hidden",
                        opacity:  timer >= 2 ? 1 : 0,
                    }}>
                        <h6>Quality Planning</h6>
                        <div className={styles.number_div}>
                            2
                        </div>
                    </div>
                    <div className={styles.heading_number_div} style={{
                        visibility: timer >= 4 ? "visible" : "hidden",
                        opacity:  timer >= 4 ? 1 : 0,
                    }}>
                        <div className={styles.number_div}>
                            4
                        </div>
                        <h6 className={styles.right_number_text}>Defect Identification</h6>
                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={7} desktop={7} ref={sectionRef} sx={{
                    ...spaceBetweenSx,
                    marginTop: "10vh"
                }}>
                    <div className={styles.heading_number_div} style={{
                        visibility: timer >= 1 ? "visible" : "hidden",
                        opacity:  timer >= 1 ? 1 : 0,
                    }}>
                        <h6>Process Definition</h6>
                        <div className={styles.number_div}>
                            1
                        </div>
                    </div>
                    <h3 className={styles.sections_headings}>
                        QA process
                    </h3>
                    <div className={styles.heading_number_div} style={{
                        visibility: timer >= 5 ? "visible" : "hidden",
                        opacity:  timer >= 5 ? 1 : 0,
                    }}>
                        <div className={styles.number_div}>
                            5
                        </div>
                        <h6 className={styles.right_number_text}>Process Improvement</h6>
                    </div>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={12} desktop={12} sx={{
                    boxSizing: "border-box",
                    marginTop: screenSize.height < 700 ? "4vh" : "10vh",
                    display: "flex",
                    gap: "1vw",
                    justifyContent: "space-between",
                }}>
                    <div className={styles.main_ul_div}>
                        <div className={styles.ul_div}  style={{
                            visibility: timer >= 1 ? "visible" : "hidden",
                            opacity:  timer >= 1 ? 1 : 0,
                        }}>
                            <h4>1</h4>
                            <ul>
                                <li>
                                    Requirements Analysis
                                </li>
                                <li>
                                    Workflow Design
                                </li>
                                <li>
                                    Standard Operating Procedures (SOPs)
                                </li>
                            </ul>
                        </div>
                        <div className={styles.ul_div}  style={{
                            visibility: timer >= 2 ? "visible" : "hidden",
                            opacity:  timer >= 2 ? 1 : 0,
                        }}>
                            <h4>2</h4>
                            <ul>
                                <li>
                                    Test Strategy
                                </li>
                                <li>
                                    Risk Assessment
                                </li>
                                <li>
                                    Resource Allocation
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.ul_div}  style={{
                        visibility: timer >= 3 ? "visible" : "hidden",
                        opacity:  timer >= 3 ? 1 : 0,
                    }}>
                        <h4>3</h4>
                        <ul>
                            <li>
                                Testing and Inspection
                            </li>
                            <li>
                                Data Collection
                            </li>
                            <li>
                                Continuous Monitoring
                            </li>
                        </ul>
                    </div>
                    <div className={styles.main_ul_div}>
                        <div className={styles.ul_div}  style={{
                            visibility: timer >= 4 ? "visible" : "hidden",
                            opacity:  timer >= 4 ? 1 : 0,
                        }}>
                            <h4>4</h4>
                            <ul>
                                <li>
                                    Defect Logging
                                </li>
                                <li>
                                    Root Cause Analysis
                                </li>
                                <li>
                                    Corrective Action
                                </li>
                            </ul>
                        </div>
                        <div className={styles.ul_div} style={{
                            visibility: timer >= 5 ? "visible" : "hidden",
                            opacity:  timer >= 5 ? 1 : 0,
                        }}>
                            <h4>5</h4>
                            <ul>
                                <li>
                                    Feedback Integration
                                </li>
                                <li>
                                    Process Optimization
                                </li>
                                <li>
                                    Training and Skill Development
                                </li>
                            </ul>
                        </div>
                    </div>

                </Grid>
            </Grid>
        </section>
    );
};

export default DesktopQaProcessSection;