import styles from "./style.module.scss";

import triangleImg from "../../../../assets/backgrounds/Layout/Navbar/Dropdown/triangle.svg";

import logo from "../../../../assets/logo.png";

import Grid from "@mui/material/Unstable_Grid2";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import NavbarDropdown from "../../../../component/molecul/Layout/Navbar/Dropdown";

const Navbar = () => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const openDropdown = () => {
        setIsDropdownOpen(true);
    };
    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const location = useLocation();
    const pathArr = location.pathname.split("/");
    const lineThroughStyle = {
        textDecoration: "line-through",
    };
    return (
        <>
            <Grid
                container
                component='header'
                spacing={{mobile: 1, tablet: 2, laptop: 0, desktop: 0}}
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))",
                    position: "fixed",
                    height: "100px",
                    margin: '0',
                    width: "100%",
                    padding: '0 7vw',
                    zIndex: 100,
                    backgroundColor: "#FFF",
                }}
            >
                <Grid mobile={6} tablet={6} laptop={2} desktop={2.80} sx={{
                    padding: 0
                }} onClick={() => {
                    if (location.pathname === "/") {
                        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                    } else {
                        navigate("/");
                    }
                }}>
                    <img src={logo} alt={"logo"} className={styles.logo}/>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={1.3} desktop={1.3}>
                    <Link
                        to={"about_us"}
                        onClick={() => {
                            if (pathArr[1] === "about_us") {
                                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                            }
                        }}
                        className={styles.navigate_link}
                        style={pathArr.includes("about_us") ? lineThroughStyle : {}}
                    >
                        about us
                    </Link>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={1.3} desktop={1.3}
                      onClick={() => {
                          if (pathArr[1] === "services") {
                              window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                          }
                      }}
                      onMouseEnter={openDropdown}
                      onMouseLeave={closeDropdown}
                      sx={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center"
                      }}
                >
                    <span
                        className={styles.navigate_link + " " + styles.services_span}
                        style={pathArr.includes("services") ? lineThroughStyle : {}}
                    >
                        services
                    </span>
                    {isDropdownOpen &&
                        <div className={styles.triangle_div}>
                            <img src={triangleImg} alt={"triangle"} className={styles.triangle_img}/>
                        </div>
                    }
                </Grid>
                <Grid mobile={6} tablet={6} laptop={1.9} desktop={1.9}>
                    <Link
                        to={"case_studies"}
                        className={styles.navigate_link}
                        style={pathArr.includes("case_studies") ? lineThroughStyle : {}}
                        onClick={() => {
                            if (pathArr[1] === "case_studies") {
                                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                            }
                        }}
                    >
                        Case Studies
                    </Link>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={1.3} desktop={1.3}>
                    <Link
                        to={"careers"}
                        className={styles.navigate_link}
                        style={pathArr.includes("careers") ? lineThroughStyle : {}}
                        onClick={() => {
                            if (pathArr[1] === "careers") {
                                window.scrollTo({top: 0, left: 0, behavior: "smooth"});
                            }
                        }}
                    >
                        Careers
                    </Link>
                </Grid>
                <Grid mobile={6} tablet={6} laptop={2.75} desktop={2.80}>
                    <Link to={"contact_us"} className={styles.contact_us_btn}>contact us</Link>
                </Grid>
            </Grid>
            {isDropdownOpen &&
                <NavbarDropdown openDropdown={openDropdown} closeDropdown={closeDropdown}/>
            }
        </>
    );
};

export default Navbar;