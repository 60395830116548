import styles from "./style.module.scss";
import CountUp from 'react-countup';
import {useNavigate} from "react-router-dom";

const MobileAboutUsSection = () => {
    const navigate = useNavigate();

    return (
        <section className={styles.main_section}>
            <div className={styles.paragraph_section}>
                <h2>
                    Welcome to <br/>Spesna - Where <br/>Innovation Meets <br/>Excellence!
                </h2>
                <p>
                    At Spesna, we embark on a journey of digital transformation, delivering cutting-edge solutions that
                    elevate your business to new heights. With a focus on Web development, QA engineering, and Design
                    services, we craft experiences that resonate with your audience and drive meaningful results.
                </p>
                <button className={styles.section_btn} onClick={() => {
                    navigate('/about_us');
                }}>
                    LEARN more
                </button>
            </div>
            <div className={styles.stats_section}>
                <div className={styles.animated_numbers_div}>
                    <h3>
                        PROJECTS
                    </h3>
                    <CountUp
                        end={250}
                        duration={5}
                        suffix="+"
                        className={styles.animated_numbers}
                        enableScrollSpy={true}
                        scrollSpyOnce={true}
                    />
                </div>

                <div className={styles.flex_row_div}>
                    <div className={styles.animated_numbers_div}>
                        <h3>
                            CUSTOMERS
                        </h3>
                        <CountUp
                            end={12}
                            duration={5}
                            suffix=""
                            className={styles.animated_numbers}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        />
                    </div>
                    <div className={styles.animated_numbers_div}>
                        <h3>
                            INDUSTRIES
                        </h3>
                        <CountUp
                            end={7}
                            duration={5}
                            suffix="+"
                            className={styles.animated_numbers}
                            enableScrollSpy={true}
                            scrollSpyOnce={true}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MobileAboutUsSection;