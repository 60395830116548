import styles from './style.module.scss';
import Grid from "@mui/material/Unstable_Grid2";
import orangeLineImg from "../../../../assets/backgrounds/Services/OrangeLine.svg";
import {useNavigate} from "react-router-dom";

const DiscoverWorksSection = () => {
    const navigate = useNavigate();
    return (
        <section className={styles.main_section}>
            <Grid
                container
                spacing={{mobile: 3, tablet: 3, laptop: 2, desktop: 3}}
                alignItems="center"
                justifyContent="center"
                style={{
                    height: "100%",
                    margin: '0',
                    width: "100%",
                }}
            >
                <Grid mobile={12} tablet={12} laptop={6} desktop={6.5}>
                    <div className={styles.section_img}/>
                </Grid>
                <Grid mobile={12} tablet={12} laptop={6} desktop={5.5}
                      sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "40px",
                      }}
                >
                    <p className={styles.section_paragraph}>
                        At Spesna, we go beyond traditional roles to embody the architects of digital excellence.
                        Founded on principles of innovation, integrity, and client-centricity, our team of developers,
                        engineers, and designers is dedicated to empowering businesses in the digital era. With a
                        passion for pushing boundaries, we craft bespoke solutions that transcend mere functionality.
                        Our commitment to unwavering integrity ensures that our products stand the test of time, built
                        on trust and transparency. As client-centric professionals, we understand the unique challenges
                        of each project, delivering tailored solutions that not only meet but exceed expectations.
                    </p>
                    <button className={styles.section_btn} onClick={() =>{
                        navigate("/case_studies");
                    }}>
                        DISCOVER works
                        <img src={orangeLineImg} alt={"line"}/>
                    </button>
                </Grid>
            </Grid>
        </section>
    );
};

export default DiscoverWorksSection;