import {useSelector} from "react-redux";
import MobileImportanceSection from "./Mobile";
import DesktopImportanceSection from "./Desktop";


const ImportanceSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileImportanceSection />
                :
                <DesktopImportanceSection />
            }
        </>
    );
};

export default ImportanceSection;