import {useSelector} from "react-redux";
import MobileAboutUsStatsSection from "./Mobile";
import DesktopAboutUsStatsSection from "./Desktop";

const AboutUsStatsSection = () => {
    const isMobile = useSelector((state) => state.screenVersion.isMobile);
    return (
        <>
            {isMobile ?
                <MobileAboutUsStatsSection />
                :
                <DesktopAboutUsStatsSection />
            }
        </>
    );
};

export default AboutUsStatsSection;