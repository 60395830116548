import styles from "./style.module.scss";

import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';


import WebDevelopment from "../../../../molecul/Home/HeroSection/Mobile/WebDevelopment";
import QualityAssurance from "../../../../molecul/Home/HeroSection/Mobile/QualityAssurance";
import UiUxSection from "../../../../molecul/Home/HeroSection/Mobile/UiUxDesign";
import {useEffect, useRef, useState} from "react";

const MobileHeroSection = () => {
    const swiperRef = useRef(null);
    const [index, setIndex] = useState(0);
    const [textIndex, setTextIndex] = useState(0);
    const [animatedText, setAnimatedText] = useState("");
    const [currentTimout, setCurrentTimout] = useState(null);
    const textArray = [
        "web development ",
        "qa engineering ",
        "ui/ux design "
    ]

    const eraseSlider = ({to, textArrIndex}) => {
        setAnimatedText("");
        swiperRef.current.swiper.slideTo(to);
        setTextIndex(textArrIndex);
        setIndex(0);
    }

    useEffect(() => {
        let textArrIndex = textIndex;
        let letterIndex = index;

        const timeout = setTimeout(() => {
            if (letterIndex < textArray[textArrIndex].length) setAnimatedText(animatedText + textArray[textArrIndex][letterIndex]);
            setIndex(letterIndex + 1);
        }, 180);
        setCurrentTimout(timeout);

        clearTimeout(currentTimout);
        if(animatedText === textArray[0]){
            textArrIndex = textArrIndex + 1;
            letterIndex = 0;
            eraseSlider({to: 1, textArrIndex: textArrIndex})
        }else  if(animatedText === textArray[1]){
            textArrIndex = textArrIndex + 1;
            letterIndex = 0;
            eraseSlider({to: 2, textArrIndex: textArrIndex})
        }else  if(animatedText === textArray[2]){
            textArrIndex = 0;
            letterIndex = 0;
            eraseSlider({to: 0, textArrIndex: textArrIndex})

        }

    }, [animatedText]);


    useEffect(() => {
        return clearTimeout(currentTimout);
    }, []);
    return (
        <section className={styles.main_section}>
            <fieldset className={styles.input_field} disabled={true}>
                <legend>Services</legend>
                {animatedText}
            </fieldset>
            <Swiper
                ref={swiperRef}
                slidesPerView={1}
                slidesPerGroup={1}
                spaceBetween={0}
                className={styles.hero_swiper}
            >
                <SwiperSlide  className={styles.hero_slide}><WebDevelopment /></SwiperSlide>
                <SwiperSlide  className={styles.hero_slide}><QualityAssurance /></SwiperSlide>
                <SwiperSlide  className={styles.hero_slide}><UiUxSection /></SwiperSlide>
            </Swiper>

        </section>
    );
};

export default MobileHeroSection;