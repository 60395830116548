import styles from "./style.module.scss";
import slackIcon from "../../../../../assets/icons/CaseStudy/slack.png";
import azureIcon from "../../../../../assets/icons/CaseStudy/azure.png";

const HealthcareQaMobile = () => {
    return (
        <section className={styles.main_section}>
            <div className={styles.first_section}>
                <h1 className={styles.section_heading}>
                    <h4>
                        #CASE STUDY
                    </h4>
                    <h1>
                        QA solution for online meat store
                    </h1>

                </h1>
                <div className={styles.section_icon}/>
            </div>
                <div className={styles.second_scroll_section + " " + styles.first_scroll_section}>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Project Type
                            </h3>
                            <h6>
                                Web Testing, Mobile Testing
                            </h6>
                        </div>
                    </div>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Industry
                            </h3>
                            <h6>
                                Supply Chain
                            </h6>
                        </div>
                    </div>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Type of Service
                            </h3>
                            <h6>
                                Manual Testing
                            </h6>
                        </div>
                    </div>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Country
                            </h3>
                            <h6>
                                USA
                            </h6>
                        </div>
                    </div>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Test coverage
                            </h3>
                            <h6>
                                Functional Testing,<br/>Performance Testing,<br/>Exploratory Testing, Smoke<br/>Testing, UI testing
                            </h6>
                        </div>
                    </div>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Cooperation Type
                            </h3>
                            <h6>
                                Full-time
                            </h6>
                        </div>
                    </div>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Communication tools
                            </h3>
                            <img src={slackIcon} alt="icon"/>
                        </div>
                    </div>
                    <div className={styles.custom_ul_div}>
                        <hr/>
                        <div>
                            <h3>
                                Bug Tracker
                            </h3>
                            <img src={azureIcon} alt="icon"/>
                        </div>
                    </div>
                </div>
            <div className={styles.scroll_section}>
                <h1>
                    Overview
                </h1>
                <p>
                    Cultivating a dynamic partnership with a prominent leader in the cybersecurity and anti-malware
                    industry, we have undertaken a comprehensive role in shaping and refining their cutting-edge
                    software solutions. Our collaborative efforts extend across the spectrum of testing methodologies,
                    encompassing both manual and automated approaches, and apply to both desktop and web-based
                    applications. Through our meticulous technical assessments, we have played a pivotal role in
                    ensuring the superior quality, heightened security, and unwavering reliability of our client's
                    anti-ransomware software and decryption tools. Together, we remain steadfast in our commitment to
                    fortify digital ecosystems against the ever-evolving landscape of cyber threats.
                </p>
            </div>
            <div className={styles.scroll_section}>
                <h1>
                    Solution
                </h1>
                <h3>
                    Manual Testing:
                </h3>
                <ul>
                    <li>
                        Sanity Testing
                    </li>
                    <li>
                        Smoke Testing
                    </li>
                    <li>
                        Regression Testing
                    </li>
                    <li>
                        Issue Tracking with Azure DevOps
                    </li>
                    <li>
                        Load and Performance Testing
                    </li>
                </ul>
                <h3>
                    Automated Testing:
                </h3>
                <ul>
                    <li>
                        Test Case Creation
                    </li>
                    <li>
                        Automation Framework Development
                    </li>
                    <li>
                        Regression Test Automation
                    </li>
                    <li>
                        Performance Testing
                    </li>
                </ul>
            </div>

        </section>
    );
};

export default HealthcareQaMobile;